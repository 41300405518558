<template>
    <div class="d-flex align-center">
        <div class="mr-2">
            <slot name="icon" />
        </div>
        <div class="title font-weight-semibold dark-text">
            <slot />
        </div>
    </div>
</template>
