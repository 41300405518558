<template>
    <div class="number-input d-inline-block">
        <v-layout align-center>
            <v-btn
                icon="mdi-minus"
                size="small"
                :title="decreaseBtnLabel"
                color="#fafafa"
                class="decrease elevation-0 btn-num-input"
                @click="__modelValue--"
            />
            <v-text-field
                :id="id"
                v-model="__modelValue"
                type="number"
                :min="min"
                :max="max"
                step="1"
                variant="solo"
                flat
                hide-details
                class="flex-shrink-1 number-input__input"
            />
            <v-btn
                icon="mdi-plus"
                size="small"
                :title="increaseBtnLabel"
                color="#fafafa"
                class="increase elevation-0 btn-num-input"
                @click="__modelValue++"
            />
        </v-layout>
    </div>
</template>

<script lang="ts" setup>
import { useVModel } from '@vueuse/core';
import { computed } from 'vue';

interface Props {
    modelValue: number;
    id: string;
    min?: number;
    max?: number;
    increaseBtnLabel?: string;
    decreaseBtnLabel?: string;
}

const props = withDefaults(defineProps<Props>(), {
    modelValue: 1,
    min: 1,
    max: 99,
    increaseBtnLabel: 'Increase',
    decreaseBtnLabel: 'Decrease'
});
const _modelValue = useVModel(props, 'modelValue');
const __modelValue = computed<number>({
    get() {
        return _modelValue.value;
    },
    set(value: number) {
        let _value = Number(value);

        if (_value < props.min) _value = props.min;
        else if (_value > props.max) _value = props.max;

        _modelValue.value = _value;
    }
});
</script>

<style lang="scss">
.number-input {
    &__input {
        width: 56px;
        height: 56px;
        border-radius: 50%;
    }

    .v-input__control {
        min-height: 0;

        .v-field__field {
            width: 56px;
            height: 56px;
            border-radius: 50%;
            background-color: #51b353;
        }

        .v-field__input {
            color: #fff;
            padding: 0;
            text-align: center;
            -moz-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
}
</style>
