<template>
    <v-card ref="timepickerRef" class="timepicker">
        <v-card-title class="primary" />
        <v-card-text class="pa-0">
            <v-row no-gutters>
                <v-col cols="6" class="timepicker__list">
                    <div
                        v-for="hours in hoursList"
                        :key="hours"
                        class="timepicker__list-item"
                        :class="{
                            'timepicker__list-item--disabled': !allowedHours(hours),
                            'timepicker__list-item--selected': isSelectedHours(hours)
                        }"
                        @click="setHours(hours)"
                    >
                        {{ hours }}
                    </div>
                </v-col>
                <v-col cols="6" class="timepicker__list">
                    <div
                        v-for="minutes in minutesList"
                        :key="minutes"
                        class="timepicker__list-item"
                        :class="{
                            'timepicker__list-item--disabled': !allowedMinutes(minutes),
                            'timepicker__list-item--selected': isSelectedMinutes(minutes)
                        }"
                        @click="setMinutes(minutes)"
                    >
                        {{ minutes }}
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <slot name="actions" />
        </v-card-actions>
    </v-card>
</template>

<script lang="ts" setup>
import { useVModel } from '@vueuse/core';
import { computed, nextTick, ref, onMounted } from 'vue';
import { VCard } from 'vuetify/components';

const timeRegex = /(\d{0,2}):(\d{0,2})/;

interface Props {
    modelValue: string;
    allowedTime?: (value: string) => boolean;
    min?: string;
    max?: string;
    hourStep?: number;
    minuteStep?: number;
}

const props = withDefaults(defineProps<Props>(), {
    allowedTime: () => true,
    min: '00:00',
    max: '23:59',
    hourStep: 1,
    minuteStep: 1
});
const _modelValue = useVModel(props, 'modelValue');

const timepickerRef = ref<VCard>(null);
const selected = ref<HTMLElement[]>([]);

onMounted(() => {
    selected.value = timepickerRef.value.$el.querySelectorAll('.timepicker__list-item--selected');

    nextTick(() => {
        selected.value.forEach(el => el.scrollIntoView());
    });
});

const hoursList = computed((): string[] => {
    let result = [];
    for (let i = 0; i < 24; i++) {
        if (i % props.hourStep === 0) {
            result.push(i.toString().padStart(2, '0'));
        }
    }
    return result;
});

const minutesList = computed((): string[] => {
    let result = [];
    for (let i = 0; i < 60; i++) {
        if (i % props.minuteStep === 0) {
            result.push(i.toString().padStart(2, '0'));
        }
    }
    return result;
});

const time = computed((): { hours: string; minutes: string } => {
    if (!_modelValue.value) {
        return {
            hours: '00',
            minutes: '00'
        };
    }

    const [, hours, minutes] = _modelValue.value.match(timeRegex);
    return {
        hours: hours.padStart(2, '0'),
        minutes: minutes.padStart(2, '0')
    };
});

const allowedTimeList = computed(() => {
    let result = {};
    const [, minHours, minMinutes] = props.min.match(timeRegex);
    const [, maxHours, maxMinutes] = props.max.match(timeRegex);
    const _minHours = parseInt(minHours),
        _maxHours = parseInt(maxHours);
    const _minMinutes = parseInt(minMinutes),
        _maxMinutes = parseInt(maxMinutes);

    for (let i = 0; i < hoursList.value.length; i++) {
        const h = parseInt(hoursList.value[i]);

        for (let j = 0; j < minutesList.value.length; j++) {
            const m = parseInt(minutesList.value[j]);

            if (h * 3600 + m * 60 < _minHours * 3600 + _minMinutes * 60 || h * 3600 + m * 60 > _maxHours * 3600 + _maxMinutes * 60)
                continue;

            const hours = hoursList.value[i];
            const minutes = minutesList.value[j];

            if (props.allowedTime(`${hours}:${minutes}`)) {
                if (result[hours]) {
                    result[hours].push(minutes);
                } else {
                    result[hours] = [minutes];
                }
            }
        }
    }

    return result;
});

nextTick(() => {
    selected.value.forEach(el => el.scrollIntoView());
});

function isSelectedHours(value: string) {
    return value === time.value.hours;
}

function isSelectedMinutes(value: string) {
    return value === time.value.minutes;
}

function setHours(value: string) {
    if (!allowedHours(value)) return;
    _modelValue.value = `${value}:${time.value.minutes}`;
}

function setMinutes(value: string) {
    if (!allowedMinutes(value)) return;
    _modelValue.value = `${time.value.hours}:${value}`;
}

function allowedHours(value: string) {
    return allowedTimeList.value[value];
}

function allowedMinutes(value: string) {
    return allowedTimeList.value[time.value.hours] && allowedTimeList.value[time.value.hours].indexOf(value) !== -1;
}
</script>

<style lang="scss">
.timepicker {
    &__list {
        height: 305px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: #ccc;
        }

        &::-webkit-scrollbar-track {
            background: #efefef;
        }
    }

    &__list-item {
        padding: 10px 0;
        font-size: 20px;
        text-align: center;
        cursor: pointer;
        transition: font-size 0.3s;

        &--disabled {
            opacity: 0.4;
            cursor: default;
            font-size: 20px !important;
        }

        &--selected {
            color: #36a14b;
            font-size: 32px;
        }

        &:hover,
        &:active {
            background: rgba(0, 0, 0, 0.04);
        }
    }
}
</style>
