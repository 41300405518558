// ray-casting algorithm based on
// http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
export function inside(point: { lat: number; lng: number }, paths: Array<{ lat: number; lng: number }>) {
    const x = point.lat,
        y = point.lng;

    if (paths.length === 0) return false;

    let inside = false;
    for (let i = 0, j = paths.length - 1; i < paths.length; j = i++) {
        const xi = paths[i].lat,
            yi = paths[i].lng;
        const xj = paths[j].lat,
            yj = paths[j].lng;

        const intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
    }

    return inside;
}

// ref: https://stackoverflow.com/questions/14505565/detect-if-a-set-of-points-in-an-array-that-are-the-vertices-of-a-complex-polygon
export function isClockwise(poly: google.maps.LatLngLiteral[]) {
    let area = 0;
    for (let i = 0; i < poly.length; i++) {
        const j = (i + 1) % poly.length;
        area += poly[i].lng * poly[j].lat;
        area -= poly[j].lng * poly[i].lat;
    }
    return area / 2 > 0;
}
