import { Moment } from 'moment';
import IsWithinOperatingHours from '@/Validators/OperatingHoursValidators';
import { AbstractDayTimeRuleToDays, TimeRuleDaysDto } from '@/EnumsDto/TimeRuleDaysDto';
import { ServiceTimeRulesDto } from '@/Dto/ServiceTimeRulesDto';

/**
 * Calculates the next valid time based on provided time rules.
 *
 * @param baseTime Moment value to use as base
 * @param timeRules Collection of time rules
 */
export function timeRulesToClosestMomentInFuture(baseTime: Moment, timeRules: ServiceTimeRulesDto[]): Moment {
    if (!timeRules || timeRules.length === 0) {
        return null;
    }

    const moments = [];

    // push all possible future days in [] of moments
    timeRules.forEach((timeRule: ServiceTimeRulesDto) => {
        const timeToCheckMidnight = baseTime.clone().startOf('day');

        // again not dealing with 1 to 1
        if (timeRule.days > 7) {
            // iterate and add possible days
            AbstractDayTimeRuleToDays[timeRule.days].forEach((dayOfWeek: TimeRuleDaysDto) => {
                const futureMoment = timeToCheckMidnight.clone().isoWeekday(dayOfWeek).add(timeRule.startTs, 'seconds');
                // if the created Moment is in the past, add a week
                if (futureMoment.isBefore(baseTime)) {
                    futureMoment.add(1, 'week');
                }
                moments.push(futureMoment);
            });
        } else {
            const futureMoment = timeToCheckMidnight.isoWeekday(timeRule.days).add(timeRule.startTs, 'seconds');
            // if the created Moment is in the past, add a week
            if (futureMoment.isBefore(baseTime)) {
                futureMoment.add(1, 'week');
            }

            moments.push(futureMoment);
        }
    });

    // return the closest day/moment
    return moments.length === 0 ? null : moments.reduce((prev: Moment, curr: Moment) => (curr.isBefore(prev) ? curr : prev));
}

/**
 * Fetches the most relevant time rule.
 * Individual days have higher priority than generic collections. i.e. Monday > Weekdays
 *
 * @param timeToCheck Base Moment instance
 * @param timeRules Collection of time rules
 */
export function getMatchingTimeRule(timeToCheck: Moment, timeRules: ServiceTimeRulesDto[]): ServiceTimeRulesDto {
    if (!timeToCheck || !timeRules || timeRules.length === 0) return null;

    const valueDay = timeToCheck.isoWeekday();
    const midnight = timeToCheck.clone().startOf('day');
    const valueTime = timeToCheck.diff(midnight, 'seconds');

    const matchingRules = timeRules.filter(timeRule =>
        timeRule.days > 7
            ? AbstractDayTimeRuleToDays[timeRule.days].indexOf(valueDay) > -1 && valueTime >= timeRule.startTs && valueTime < timeRule.endTs
            : valueDay === timeRule.days && valueTime >= timeRule.startTs && valueTime < timeRule.endTs
    );

    return matchingRules.length === 0 ? null : matchingRules.reduce((prev, curr) => (curr.days < prev.days ? curr : prev));
}

/**
 * Validates the Moment instance against the given time rules,
 * and adjusts it if necessary.
 *
 * @param timeToCheck Moment value to validate and adjust
 * @param timeRules Collection of time rules
 */
export function adjustTimeWithinOperatingHours(timeToCheck: Moment, timeRules: ServiceTimeRulesDto[]): Moment {
    // if data is valid, return as-is
    if (!timeRules || timeRules.length === 0 || IsWithinOperatingHours.validate(timeToCheck, timeRules)) {
        return timeToCheck;
    }

    // timeToCheck values for timeRules
    const midnight = timeToCheck.clone().startOf('day');
    const valueTime = timeToCheck.diff(midnight, 'seconds');

    // check if any timerules match our day, filter array to the rules that are applicable
    const matchingRule = getMatchingTimeRule(timeToCheck, timeRules);

    if (matchingRule) {
        // the day fits fine, offer closest time to chosen one
        if (valueTime < matchingRule.startTs && valueTime < matchingRule.endTs) {
            // too early
            const secondsToAdd = matchingRule.startTs - valueTime;

            return timeToCheck.clone().add(secondsToAdd, 'seconds');
        } else {
            // too late, let's find a date in the future
            return timeRulesToClosestMomentInFuture(timeToCheck, timeRules);
        }
    } else {
        // no matching days, offer a day in the future
        return timeRulesToClosestMomentInFuture(timeToCheck, timeRules);
    }
}
