<template>
    <v-autocomplete
        ref="autocompleteRef"
        v-model="_modelValue"
        v-model:search="search"
        :loading="isLoading"
        :items="items"
        :label="label"
        item-title="address"
        item-value="place_id"
        menu-icon=""
        :prepend-inner-icon="icon"
        :append-inner-icon="appendIcon"
        return-object
        hide-no-data
        hide-selected
        hide-details
        variant="solo"
        color="#555"
        bg-color="#fff"
        rounded="0"
        :menu-props="{ contentClass: 'rounded-t-0' }"
        @update:model-value="onInput"
        @click:append-inner="onClear"
        @focus="selectAll"
    >
        <template #append-item>
            <div class="d-flex justify-end px-2 pt-2">
                <div class="d-flex flex-shrink align-center">
                    <span class="mr-1 caption">Powered by</span>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg" alt="Google" height="20" />
                </div>
            </div>
        </template>
    </v-autocomplete>
</template>

<script lang="ts" setup>
import { MapLocation } from '@/Models/MapLocation';
import { LatLngLiteral } from 'leaflet';
import { formatAddress } from '@/Helpers/GeocoderResultHelpers';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { type VAutocomplete } from 'vuetify/components';
import { useVModel } from '@vueuse/core';
import { isEmpty } from 'lodash-es';

interface Props {
    modelValue: MapLocation | null;
    icon?: string;
    label: string;
}

interface Emits {
    (e: 'update:modelValue', value: MapLocation | null): void;
    (e: 'input:userSelected', value: any): void;
    (e: 'loading', value: boolean): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();
const _modelValue = useVModel(props, 'modelValue');

const autocompleteRef = ref<VAutocomplete>();
const search = ref<string>('');
const predictions = ref<Partial<google.maps.places.AutocompletePrediction>[]>([]);
const isLoading = ref<boolean | string>(false);
const appendIcon = computed(() => (!isEmpty(_modelValue.value) || search.value ? 'mdi-close' : undefined));

const autocompleteService = new google.maps.places.AutocompleteService();
const placesService: google.maps.places.PlacesService = new google.maps.places.PlacesService(document.createElement('div'));
const geocoder: google.maps.Geocoder = new google.maps.Geocoder();
const skipSearch = ref(false);

// types of addresses we don't want to use
// https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
const addressTypeBlacklist = ['political', 'postal_town', 'plus_code'];

// maximum distance in metres from selected coordinates - used by geocoder to determine if location is valid
const maxDistanceFromCoordinates = 100;

const items = computed(() => predictions.value.map<MapLocation>(({ place_id, description }) => ({ place_id, address: description })));

// onMounted(() => {
//     if (props.modelValue) {
//         // set initial values
//         const { place_id, address: description } = props.modelValue;
//         search.value = description;

//         if (description && place_id)
//             predictions.value = [
//                 {
//                     description,
//                     place_id
//                 }
//             ];
//     }
// });

watch(search, onSearchValueChanged);

function onSearchValueChanged(value: string) {
    if (!value || skipSearch.value) {
        // reset skipSearch to allow subsequent searches
        skipSearch.value = false;
        return;
    }

    // if request is still in progress, do nothing
    if (isLoading.value) return;
    setLoading(true);

    autocompleteService.getPlacePredictions(
        {
            componentRestrictions: { country: 'no' },
            input: value
        },
        (result, status) => {
            setLoading(false);

            if (status === google.maps.places.PlacesServiceStatus.OK) {
                predictions.value = result;
            }
        }
    );
}

function onInput(value: MapLocation) {
    if (!value) {
        return;
    }

    const { place_id, address } = value;
    if (place_id) {
        setLoading(true);

        // load place details from place ID
        placesService.getDetails({ placeId: place_id }, (result, status) => {
            setLoading(false);

            if (status === google.maps.places.PlacesServiceStatus.OK) {
                // TODO: should we return address, or place description?
                emit('input:userSelected', {
                    address,
                    location: result.geometry.location.toJSON(),
                    place_id
                });

                blur();
            }
        });
    }
}

function onClear() {
    predictions.value = [];
    _modelValue.value = null;
    search.value = '';
}

function setCoordinates(value: LatLngLiteral) {
    if (!value) return;

    setLoading(true);

    // reset model
    _modelValue.value = null;

    geocoder.geocode({ location: value }, (results, status) => {
        if (status !== google.maps.GeocoderStatus.OK || !results.length) {
            setLoading(false);
            return;
        }

        // we only want valid places
        const validPlaces = results.filter(x => !x.types.some(t => addressTypeBlacklist.includes(t)));

        if (!validPlaces.length) {
            setLoading(false);
            return;
        }

        // we only want places that are within than x metres from given coordinates
        const resultDistance = validPlaces.reduce(
            (acc, curr) => {
                const distance = google.maps.geometry.spherical.computeDistanceBetween(
                    curr.geometry.location,
                    new google.maps.LatLng(value)
                );

                if (distance < acc.distance) {
                    acc = {
                        place: curr,
                        distance
                    };
                }

                return acc;
            },
            {
                place: validPlaces[0],
                distance: google.maps.geometry.spherical.computeDistanceBetween(
                    validPlaces[0].geometry.location,
                    new google.maps.LatLng(value)
                )
            }
        );

        if (resultDistance.distance > maxDistanceFromCoordinates) {
            setLoading(false);
            return;
        }

        const {
            place_id,
            address_components,
            geometry: { location }
        } = resultDistance.place;

        const description = formatAddress(address_components);

        // this prevents search from firing due to model change - we know what we're doing
        skipSearch.value = true;
        predictions.value = [
            {
                description,
                place_id
            }
        ];

        _modelValue.value = {
            address: description,
            place_id,
            location: location.toJSON()
        };

        setLoading(false);
    });
}

function setLoading(value: boolean) {
    isLoading.value = value ? 'primary' : false;
    emit('loading', value);
}

function selectAll() {
    nextTick(() => autocompleteRef.value?.$el.querySelector('input').select());
}

function blur() {
    nextTick(() => autocompleteRef.value?.$el.querySelector('input').blur());
}

defineExpose({ setLoading, setCoordinates });
</script>
