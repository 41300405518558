<template>
    <div
        class="overlay py-6 px-3 h-100 d-flex flex-column align-center"
        :class="{ 'overlay__icon overlay__icon--pickup': !hasValue && showIcon }"
    >
        <div class="page-content pointer-events-all">
            <location-input
                id="pickupLocationInput"
                ref="locationInputRef"
                v-model="mapLocation"
                :label="t('labels.from')"
                class="elevation-3"
                @update:model-value="onInput"
                @input:user-selected="onInputUserSelected"
            />
        </div>
        <v-spacer />
        <div class="page-content mb-2">
            <div class="d-flex flex-column align-end justify-end">
                <div v-if="infoBtnLinkUrl" class="pointer-events-all">
                    <v-btn icon color="#fff" class="ma-0 mb-2 round-btn" :href="sanitizeUrl(infoBtnLinkUrl)" target="_blank">
                        <v-img src="/icons/info-icon.svg" width="32" height="32" alt="info" />
                    </v-btn>
                </div>
                <div class="pointer-events-all">
                    <geolocate-button
                        v-if="geolocation.isAvailable"
                        ref="geolocateBtnRef"
                        :geolocation="geolocation"
                        class="ma-0"
                        @input="onGeolocateInput"
                    />
                </div>
            </div>
        </div>
        <div class="page-content">
            <div class="pointer-events-all">
                <v-sheet v-if="discontinueMessage" color="white" class="pa-4">
                    <v-row dense class="flex-nowrap">
                        <v-col cols="auto" class="d-flex align-center">
                            <v-icon icon="mdi-alert-octagon" size="x-large" color="error" />
                        </v-col>
                        <v-col cols="auto" class="flex-shrink-1">
                            <div class="font-weight-medium" v-html="sanitizeHtml(formattedDiscontinuedMessage)"></div>
                        </v-col>
                    </v-row>
                </v-sheet>
                <template v-else>
                    <v-btn
                        v-show="showSetLocationButton"
                        id="nextButton"
                        :loading="isLoading"
                        :disabled="isLoading || !isValid(isInServiceArea)"
                        color="primary"
                        rounded
                        size="large"
                        block
                        @click="setLocation"
                    >
                        <template v-if="mapLocation && !isInServiceArea">{{ t('labels.outOfServiceArea') }}</template>
                        <template v-else>{{ t('buttons.pickMeUpHere') }}</template>
                    </v-btn>
                </template>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { PickupDropoffProps } from '@/Models/PickupDropoff';
import GeolocateButton from '@/components/GeolocateButton.vue';
import LocationInput from '@/components/LocationInput.vue';
import { ServiceDto } from '@/Dto/ServiceDto';
import { MapLocation } from '@/Models/MapLocation';
import { inside } from '@/Helpers/VueGmapHelpers';
import { RequestParameters } from '@/Models/RequestParameters';
import { linkify } from '@/Helpers/StringHelpers';
import { useI18n } from 'vue-i18n';
import { ref, computed, nextTick } from 'vue';
import { usePickupDropoff, Emits as BaseEmits } from './usePickupDropoff';
import { useVModel } from '@vueuse/core';
import { sanitizeUrl } from '@braintree/sanitize-url';
import sanitizeHtml from 'sanitize-html';

interface PickupTabProps extends PickupDropoffProps {
    showIcon: boolean;
    showSetLocationButton: boolean;
    discontinueMessage?: string;
}

interface Emits extends BaseEmits {
    (e: 'update:modelValue', value: RequestParameters): void;
    (e: 'nav:next'): void;
}

const props = defineProps<PickupTabProps>();
const emit = defineEmits<Emits>();
const { t } = useI18n();
const _modelValue = useVModel(props, 'modelValue');
const geolocateBtnRef = ref<InstanceType<typeof GeolocateButton>>();

const {
    locationInputRef,
    isValid,
    mapLocation,
    onInput,
    onInputUserSelected,
    onGeolocateInput,
    mapZoomTo,
    setLocationInputCenter,
    geolocation,
    center
} = usePickupDropoff(props, emit);

const service = computed((): ServiceDto => {
    const { location } = mapLocation.value || ({} as MapLocation);
    if (!location) return null;

    return props.services.filter(s => {
        if (!s.pickupZones || s.pickupZones.length === 0) return false;

        const { lat, lng } = location;
        return inside(
            { lat, lng },
            s.pickupZones[0].area.coordinates[0].map(c => ({
                lat: c[1],
                lng: c[0]
            }))
        );
    })[0];
});

const isInServiceArea = computed(() => {
    return service.value != null;
});

const hasValue = computed(() => !!props.modelValue.requestedPickupLocation);

const formattedDiscontinuedMessage = computed(() => {
    return linkify(props.discontinueMessage);
});

async function init() {
    const { requestedPickupAddress: address, requestedPickupLocation: location, requestedPickupPlaceId: place_id } = props.modelValue || {};

    // if there are predefined values, map it to local model
    if (address || location || place_id) {
        mapLocation.value = {
            address,
            location,
            place_id
        };

        // focus on selected location
        await mapZoomTo(location);

        nextTick(
            () =>
                (_modelValue.value = {
                    ...props.modelValue,
                    requestedPickupAddress: undefined,
                    requestedPickupLocation: undefined,
                    requestedPickupPlaceId: undefined,
                    service: undefined
                })
        );
    } else if (center.value && props.watchCenter) {
        setLocationInputCenter(center.value);
    }
}

function setLocation() {
    const {
        address: requestedPickupAddress,
        location: requestedPickupLocation,
        place_id: requestedPickupPlaceId
    } = mapLocation.value || ({} as MapLocation);

    _modelValue.value = {
        ...props.modelValue,
        requestedPickupAddress,
        requestedPickupLocation,
        requestedPickupPlaceId,
        service: service.value
    };
    emit('nav:next');
}

defineExpose({ init });
</script>
