import { IRequestService } from '@/Services/IRequestService';
import { inject, injectable } from 'inversify';
import { RequestEstimateDto } from '@/Dto/RequestEstimateDto';
import { CreateRequestResponseDto } from '@/Dto/CreateRequestResponseDto';
import { RequestDto } from '@/Dto/RequestDto';
import { Request } from '@/Models/Request';
import { VehicleLocationDto } from '@/Dto/VehicleLocationDto';
import { AxiosStatic, AxiosError, AxiosResponse, isAxiosError } from 'axios';
import AppConfig from '@/AppConfig';
import { ShortAddressFilter } from '@/Filters/AddressFilters';
import { RequestStatusDto } from '@/EnumsDto/RequestStatusDto';
import { RequestEstimate } from '@/Models/RequestEstimate';
import { RequestParameters } from '@/Models/RequestParameters';
import { LineStringToLatLngLiteralArray, PointToLatLng } from '@/Helpers/GeoJsonHelpers';
import { NumberToMoment } from '@/Helpers/NumberHelpers';
import { LatLngLiteral } from 'leaflet';
import { EstimateServiceDto } from '@/Dto/EstimateServiceDto';
import { camelize } from '@/Helpers/StringHelpers';

const { requestedFlexibility } = AppConfig;

function toRequestEstimate(requestEstimateDto: RequestEstimateDto): RequestEstimate {
    return {
        ...requestEstimateDto,
        requestedPickupTs: NumberToMoment(requestEstimateDto.requestedPickupTs, AppConfig.timezone),
        requestedPickupLocation: PointToLatLng(requestEstimateDto.requestedPickupLocation),
        requestedDropoffLocation: PointToLatLng(requestEstimateDto.requestedDropoffLocation),
        scheduledPickupTs: NumberToMoment(requestEstimateDto.scheduledPickupTs, AppConfig.timezone),
        scheduledPickupLocation: PointToLatLng(requestEstimateDto.scheduledPickupLocation),
        scheduledDropoffLocation: PointToLatLng(requestEstimateDto.scheduledDropoffLocation),
        pickupWalkingPolyline: LineStringToLatLngLiteralArray(requestEstimateDto.pickupWalkingPolyline),
        dropoffWalkingPolyline: LineStringToLatLngLiteralArray(requestEstimateDto.dropoffWalkingPolyline)
    };
}

// TODO: rethink how the DTO objects should look like, especially for error messages. Should the API return error message keys instead?
@injectable()
export default class RequestService implements IRequestService {
    @inject('axios')
    protected http: AxiosStatic;

    getNextAvailableRequestEstimate(params: RequestParameters): Promise<RequestEstimate> {
        return new Promise<RequestEstimate>((resolve, reject) => {
            const { requestedPickupLocation, requestedDropoffLocation, service, numRiders } = params;
            const { lat: requestedPickupLatitude, lng: requestedPickupLongitude } = requestedPickupLocation || ({} as LatLngLiteral);
            const { lat: requestedDropoffLatitude, lng: requestedDropoffLongitude } = requestedDropoffLocation || ({} as LatLngLiteral);
            const { id: serviceId } = service || ({} as EstimateServiceDto);

            this.http
                .get<RequestEstimateDto>(`${AppConfig.apiRoutePrefix}/estimates/nextAvailable`, {
                    params: {
                        requestedPickupLatitude,
                        requestedPickupLongitude,
                        requestedDropoffLatitude,
                        requestedDropoffLongitude,
                        serviceId,
                        numRiders,
                        requestedFlexibility
                    }
                })
                .then(response => resolve(toRequestEstimate(response.data)))
                .catch(e => {
                    if (isAxiosError(e)) {
                        const { response } = e;
                        const { status = 500, data } = response || {};

                        let message: string;
                        switch (status) {
                            case 400:
                                message = data ? `errorMessages.${camelize(data)}` : null;
                                break;
                            case 404:
                                message = 'errorMessages.noServiceFound';
                                break;
                            default: {
                                message = data || 'errorMessages.defaultErrorMessage';
                            }
                        }

                        reject({ status, message });
                    } else {
                        reject(e);
                    }
                });
        });
    }

    getRequestEstimates(params: RequestParameters): Promise<RequestEstimate | RequestEstimate[]> {
        return new Promise<RequestEstimate | RequestEstimate[]>((resolve, reject) => {
            const { requestedPickupLocation, requestedDropoffLocation, service, numRiders, requestedPickupTs } = params;
            const { lat: requestedPickupLatitude, lng: requestedPickupLongitude } = requestedPickupLocation || ({} as LatLngLiteral);
            const { lat: requestedDropoffLatitude, lng: requestedDropoffLongitude } = requestedDropoffLocation || ({} as LatLngLiteral);
            const { id: serviceId } = service || ({} as EstimateServiceDto);

            this.http
                .get<RequestEstimateDto | RequestEstimateDto[]>(`${AppConfig.apiRoutePrefix}/estimates/request`, {
                    params: {
                        requestedPickupLatitude,
                        requestedPickupLongitude,
                        requestedDropoffLatitude,
                        requestedDropoffLongitude,
                        requestedPickupTs: requestedPickupTs.unix(),
                        serviceId,
                        numRiders,
                        requestedFlexibility
                    }
                })
                .then(response => {
                    if (response.data instanceof Array) {
                        resolve(response.data.map(x => toRequestEstimate(x)));
                    } else {
                        resolve(toRequestEstimate(response.data));
                    }
                })
                .catch(e => {
                    if (isAxiosError(e)) {
                        const { response } = (e as AxiosError) || ({} as AxiosError);
                        const { status, data = null } = response || ({} as AxiosResponse);

                        let message: string;
                        switch (status) {
                            case 400: {
                                const errorString = camelize(data);
                                message = errorString ? `errorMessages.${errorString}` : null;
                                break;
                            }
                            case 404:
                                message = 'errorMessages.noServiceFound';
                                break;
                            default: {
                                message = data || 'errorMessages.defaultErrorMessage';
                            }
                        }

                        reject({
                            status: status || 500,
                            message
                        });
                    } else {
                        reject(e);
                    }
                });
        });
    }

    createRequest(params: RequestParameters): Promise<CreateRequestResponseDto> {
        return new Promise<CreateRequestResponseDto>((resolve, reject) => {
            const {
                requestedPickupAddress,
                requestedPickupLocation,
                requestedDropoffAddress,
                requestedDropoffLocation,
                service,
                numRiders,
                requestedPickupTs,
                firstName,
                phoneNumber,
                estimateId
            } = params;
            const { lat: requestedPickupLatitude, lng: requestedPickupLongitude } = requestedPickupLocation || ({} as LatLngLiteral);
            const { lat: requestedDropoffLatitude, lng: requestedDropoffLongitude } = requestedDropoffLocation || ({} as LatLngLiteral);
            const { id: serviceId } = service || ({} as EstimateServiceDto);

            this.http
                .post<CreateRequestResponseDto>(`${AppConfig.apiRoutePrefix}/requests`, {
                    requestedPickupAddress: ShortAddressFilter(requestedPickupAddress),
                    requestedPickupLatitude,
                    requestedPickupLongitude,
                    requestedDropoffAddress: ShortAddressFilter(requestedDropoffAddress),
                    requestedDropoffLatitude,
                    requestedDropoffLongitude,
                    requestedPickupTs: requestedPickupTs.unix(),
                    numRiders,
                    firstName,
                    phoneNumber,
                    serviceId,
                    requestedFlexibility,
                    estimateId
                })
                .then(response => {
                    const getStatus = () => {
                        let retries = 0,
                            status = RequestStatusDto.Processing;

                        this.getRequest(response.data.companyId, response.data.id).then(request => {
                            status = request.status;
                            retries++;

                            if (retries <= 10 && status < RequestStatusDto.Accepted) {
                                setTimeout(getStatus, 1000);
                            } else {
                                resolve(response.data);
                            }
                        });
                    };

                    getStatus();
                })
                .catch(e => {
                    if (isAxiosError(e)) {
                        const { response } = (e as AxiosError) || ({} as AxiosError);
                        const { status, data } = response || ({} as AxiosResponse);

                        reject({
                            status: status || 500,
                            message: data || 'errorMessages.defaultErrorMessage'
                        });
                    } else {
                        reject(e);
                    }
                });
        });
    }

    getRequest(companyId: string, id: string): Promise<Request> {
        return new Promise<Request>((resolve, reject) => {
            this.http
                .get<RequestDto>(`${AppConfig.apiRoutePrefix}/requests/${companyId}/${id}`)
                .then(response => {
                    resolve({
                        ...response.data,
                        companyId,
                        requestedPickupTs: NumberToMoment(response.data.requestedPickupTs, AppConfig.timezone),
                        requestedPickupLocation: PointToLatLng(response.data.requestedPickupLocation),
                        requestedDropoffLocation: PointToLatLng(response.data.requestedDropoffLocation),
                        scheduledPickupTs: NumberToMoment(response.data.scheduledPickupTs, AppConfig.timezone),
                        scheduledPickupLocation: PointToLatLng(response.data.scheduledPickupLocation),
                        scheduledDropoffLocation: PointToLatLng(response.data.scheduledDropoffLocation),
                        pickupWalkingPolyline: LineStringToLatLngLiteralArray(response.data.pickupWalkingPolyline),
                        dropoffWalkingPolyline: LineStringToLatLngLiteralArray(response.data.dropoffWalkingPolyline),
                        pickupEta: NumberToMoment(response.data.pickupEta, AppConfig.timezone),
                        dropoffEta: NumberToMoment(response.data.dropoffEta, AppConfig.timezone),
                        scheduledDropoffTs: NumberToMoment(response.data.scheduledDropoffTs, AppConfig.timezone)
                    });
                })
                .catch(e => {
                    if (isAxiosError(e)) {
                        const { response } = e || {};
                        const { status, data } = response || {};

                        let message: string;
                        switch (status) {
                            case 404: {
                                switch (data) {
                                    case 'ExpiredError':
                                        message = 'errorMessages.requestExpired';
                                        break;
                                    case 'CancelledError':
                                        message = 'messages.requestCancelled';
                                        break;
                                    case 'CompletedError':
                                        message = 'errorMessages.requestCompleted';
                                        break;
                                    case 'NotFoundError':
                                    default:
                                        message = 'errorMessages.requestNotFound';
                                }
                                break;
                            }
                            default: {
                                message = data || 'errorMessages.defaultErrorMessage';
                            }
                        }
                        reject({
                            status: status || 500,
                            message
                        });
                    } else {
                        reject(e);
                    }
                });
        });
    }

    cancelRequest(companyId: string, id: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.http
                .delete(`${AppConfig.apiRoutePrefix}/requests/${companyId}/${id}`)
                .then(() => resolve())
                .catch(e => {
                    if (isAxiosError(e)) {
                        const { response } = (e as AxiosError) || ({} as AxiosError);
                        const { status, data } = response || ({} as AxiosResponse);

                        let message: string;
                        switch (status) {
                            case 404:
                                message = 'errorMessages.requestNotFound';
                                break;
                            default: {
                                message = data || 'errorMessages.defaultErrorMessage';
                            }
                        }

                        reject({
                            status: status || 500,
                            message
                        });
                    } else {
                        reject(e);
                    }
                });
        });
    }

    getVehicleLocation(companyId: string, id: string): Promise<VehicleLocationDto> {
        return new Promise<VehicleLocationDto>((resolve, reject) => {
            this.http
                .get<VehicleLocationDto>(`${AppConfig.apiRoutePrefix}/requests/${companyId}/${id}/vehicleLocation`)
                .then(response => resolve(response.data))
                .catch(e => {
                    if (isAxiosError(e)) {
                        const { response } = (e as AxiosError) || ({} as AxiosError);
                        const { status } = response || ({} as AxiosResponse);

                        reject({
                            status: status || 500,
                            message: 'errorMessages.defaultErrorMessage'
                        });
                    } else {
                        reject(e);
                    }
                });
        });
    }
}
