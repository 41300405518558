import 'reflect-metadata';
import { Container } from 'inversify';
import axios, { AxiosStatic } from 'axios';
import { IRequestService, IRequestServiceId } from '@/Services/IRequestService';
import RequestService from '@/Services/RequestService';
import { IServiceService, IServiceServiceId } from '@/Services/IServiceService';
import ServiceService from '@/Services/ServiceService';
import { IGeolocationService, IGeolocationServiceId } from '@/Services/IGeolocationService';
import GeolocationService from '@/Services/GeolocationService';
import LocalizationService from '@/Services/LocalizationService';
import { ILocalizationService, ILocalizationServiceId } from '@/Services/ILocalizationService';
import { InjectionKey, Plugin, inject } from 'vue';
import { IConfigService, IConfigServiceId } from '@/Services/IConfigService';
import AppConfigService from '@/Services/AppConfigService';

const __INVERSIFY_SYMBOL__ = Symbol.for('app:inversify-container');
const injectionKey: InjectionKey<Container> = __INVERSIFY_SYMBOL__;

export function createContainer(): Plugin {
    const container = new Container();

    container.bind<AxiosStatic>('axios').toConstantValue(axios);
    container.bind<IConfigService>(IConfigServiceId).to(AppConfigService);
    container.bind<IRequestService>(IRequestServiceId).to(RequestService);
    container.bind<IServiceService>(IServiceServiceId).to(ServiceService);
    container.bind<IGeolocationService>(IGeolocationServiceId).to(GeolocationService).inSingletonScope();
    container.bind<ILocalizationService>(ILocalizationServiceId).to(LocalizationService).inSingletonScope();

    return {
        install(app) {
            app.provide(injectionKey, container);
        }
    };
}

export function useContainer() {
    const _container = inject(injectionKey);
    if (!_container) throw new Error('You must call createContainer() first');

    return _container;
}
