// Ref: https://stackoverflow.com/a/2970667
export function camelize(str: string): string {
    if (!str || typeof str !== 'string') return null;

    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
        if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
        return index == 0 ? match.toLowerCase() : match.toUpperCase();
    });
}

export function linkify(str: string) {
    if (!str || typeof str !== 'string') return '';

    const urls = str.match(/((((ftp|https?):\/\/)|(w{3}\.))[-\w@:%_+.~#?,&\/\/=]+)/g);
    if (urls) {
        urls.forEach(function (url) {
            str = str.replace(url, '<a target="_blank" href="' + url + '">' + url + '</a>');
        });
    }

    return str;
}
