export const ILocalizationServiceId = Symbol.for('ILocalizationService');

export interface ILocalizationService {
    /**
     * Sets app language.
     *
     * @param params Locale name
     */
    loadLanguage(params: string): void;
}
