import type { AppConfigDto } from '@/Dto/AppConfigDto';
import { IConfigService } from './IConfigService';
import { inject, injectable } from 'inversify';
import { AxiosStatic } from 'axios';
import AppConfig from '@/AppConfig';

@injectable()
export default class AppConfigService implements IConfigService {
    @inject('axios')
    protected axios: AxiosStatic;

    getConfig() {
        return new Promise<AppConfigDto>((resolve, reject) => {
            this.axios
                .get(`${AppConfig.apiRoutePrefix}/config`)
                .then(response => resolve(response.data))
                .catch(reject);
        });
    }
}
