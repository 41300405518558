<template>
    <l-feature-group @add="onAdd">
        <l-geo-json v-if="showShapes" :geojson="serviceAreaGeoJson" :options-style="serviceAreaGeoJsonOptionsStyle" />
        <template v-if="showLabels">
            <l-marker
                v-for="label in labelMarkers"
                :key="label.id"
                :lat-lng="label.center"
                :options="{ opacity: 0 }"
                @click="onLabelClick(label)"
            >
                <l-tooltip :options="tooltipOptions">{{ label.name }}</l-tooltip>
            </l-marker>
        </template>
    </l-feature-group>
</template>

<script lang="ts" setup>
import { LGeoJson, LFeatureGroup, LMarker, LTooltip } from '@vue-leaflet/vue-leaflet';
import { ServiceDto } from '@/Dto/ServiceDto';
import { FeatureCollection } from 'geojson';
import { LeafletEvent, LatLngBounds, LatLng, Map, TooltipOptions, StyleFunction } from 'leaflet';
import { getByKey } from '@/Helpers/ObjectHelpers';
import { ZoneDto } from '@/Dto/ZoneDto';
import { computed, nextTick, ref } from 'vue';

export interface ServiceLabel {
    bounds: LatLngBounds;
    center: LatLng;
    id: string;
    name: string;
    childCount: number;
}

interface Props {
    services: ServiceDto[];
    showShapes: boolean;
    showLabels: boolean;
    groupKey: string;
    groupLabels: boolean;
    shapesKey?: string;
}
interface Emits {
    (e: 'click', value: ServiceLabel): void;
}

const emit = defineEmits<Emits>();
const props = defineProps<Props>();

const map = ref<Map>();

const tooltipOptions: TooltipOptions = {
    permanent: true,
    direction: 'center',
    interactive: true,
    offset: [0, -25],
    className: 'service-label',
    opacity: 1
};

const serviceAreaGeoJsonOptionsStyle: StyleFunction = feature => {
    switch (feature.properties.type) {
        case 'children':
            return {
                stroke: true,
                color: '#00AAD5',
                opacity: 1,
                weight: 2,
                fill: false,
                interactive: false,
                dashArray: '4'
            };
        default:
            return {
                stroke: true,
                color: '#00AAD5',
                opacity: 1,
                weight: 2,
                fill: true,
                fillColor: '#B1B1B1',
                fillOpacity: 0.5,
                interactive: false
            };
    }
};

const serviceAreaGeoJson = computed((): FeatureCollection => {
    if (!props.services || !props.shapesKey) return null;

    const parentZones: ZoneDto[] = props.services
        .filter(s => props.shapesKey && s[props.shapesKey] && s[props.shapesKey].length > 0)
        // take the first zone from each service as the parent zone
        .map(s => s[props.shapesKey][0]);
    const childZones: ZoneDto[] = props.services
        // filter out services that don't have child zones
        .filter(s => props.shapesKey && s[props.shapesKey] && s[props.shapesKey].length > 1)
        // skip the first item since that is the parent zone
        .map(s => s[props.shapesKey].slice(1))
        // flatten zones into single array
        .reduce((acc, val) => acc.concat(val), []);

    return {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: {
                    type: 'parent'
                },
                geometry: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [90, -180],
                            [90, 180],
                            [-90, 180],
                            [-90, -180]
                        ],
                        // add parent zones to form holes in the grey background
                        ...parentZones.map(z => z.area.coordinates[0])
                    ]
                }
            },
            // add child zones
            {
                type: 'Feature',
                properties: {
                    type: 'children'
                },
                geometry: {
                    type: 'Polygon',
                    // add child zones to form shapes with dotted outline
                    coordinates: childZones.map(z => z.area.coordinates[0])
                }
            }
        ]
    };
});

const labelMarkers = computed((): ServiceLabel[] => {
    if (!props.services) return [];

    // merge the items if a valid property key is provided
    return props.groupLabels && props.groupKey && props.services.filter(x => getByKey(x, props.groupKey) != null).length > 0
        ? props.services.reduce((acc: any[], curr) => {
              if (!props.shapesKey) return acc;

              const prop = getByKey(curr, props.groupKey);

              // check if we have an existing item with the same group name
              const index = acc.findIndex(x => x.name === prop);
              const [zone] = curr[props.shapesKey];

              if (!zone) return acc;

              if (index !== -1) {
                  // if we do, we merge them
                  const bounds = new LatLngBounds([acc[index].bounds, ...zone.area.coordinates[0].map(([lng, lat]) => [lat, lng])]);
                  acc[index] = {
                      ...acc[index],
                      bounds,
                      center: bounds.getCenter(),
                      childCount: acc[index].childCount + 1
                  };
              } else {
                  // otherwise, we add a new item to the array
                  const bounds = new LatLngBounds(zone.area.coordinates[0].map(([lng, lat]) => [lat, lng]));
                  acc.push({
                      bounds,
                      center: bounds.getCenter(),
                      id: prop,
                      name: prop,
                      childCount: 1
                  });
              }

              return acc;
          }, [])
        : // otherwise we return mapped services
          props.shapesKey
          ? props.services
                .filter(service => !!service[props.shapesKey][0])
                .map(service => {
                    const [zone] = service[props.shapesKey];
                    const bounds = new LatLngBounds(zone.area.coordinates[0].map(([lng, lat]) => [lat, lng]));

                    return {
                        bounds,
                        center: bounds.getCenter(),
                        id: service.id,
                        name: service.name,
                        childCount: 0
                    };
                })
          : [];
});

function onAdd(e: LeafletEvent) {
    map.value = e.target._map;
}

function getBounds(serviceIds?: string[]) {
    if (!props.services) return new LatLngBounds([]);
    const services = labelMarkers.value.filter(s => !serviceIds || serviceIds.indexOf(s.id) !== -1);
    return services.reduce((acc: LatLngBounds, curr) => acc.extend(curr.bounds), new LatLngBounds([]));
}

function fitBounds(serviceIds?: string[]) {
    const bounds = getBounds(serviceIds);

    if (bounds.isValid())
        map.value.fitBounds(bounds, {
            paddingTopLeft: [50, 100],
            paddingBottomRight: [50, 50]
        });
}

function onLabelClick(label: ServiceLabel) {
    // set up one-time event handler that zooms in if zoom level is below threshold
    if (!props.groupLabels || label.childCount <= 1) {
        map.value.once('zoomend moveend', () => {
            if (map.value.getZoom() < 12) {
                map.value.setView(label.center, 12, { animate: true });
            }
        });
    }

    nextTick(() => {
        map.value.fitBounds(label.bounds, { padding: [100, 100] });
        emit('click', label);
    });
}

defineExpose({ fitBounds });
</script>

<style lang="scss">
.leaflet-tooltip.service-label {
    font-size: 16px;
    padding: 8px 16px;
    border-radius: 20px;
    background: #36a14b;
    color: #fff;
    border: 1px solid #36a14b;

    &:hover,
    &:active {
        background: darken(#36a14b, 10%);
        border-color: darken(#36a14b, 10%);
    }
}
</style>
