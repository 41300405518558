import { IServiceService } from '@/Services/IServiceService';
import { inject, injectable } from 'inversify';
import { EstimateServiceDto } from '@/Dto/EstimateServiceDto';
import { AxiosStatic, AxiosError, AxiosResponse, isAxiosError } from 'axios';
import AppConfig from '@/AppConfig';
import { ServiceDto } from '@/Dto/ServiceDto';
import { StatusDto } from '@/EnumsDto/StatusDto';
import { RequestParameters } from '@/Models/RequestParameters';
import { LatLngLiteral } from 'leaflet';

@injectable()
export default class ServiceService implements IServiceService {
    @inject('axios')
    protected axios: AxiosStatic;

    getServiceEstimate(params: RequestParameters): Promise<EstimateServiceDto> {
        return new Promise<EstimateServiceDto>((resolve, reject) => {
            const { requestedPickupLocation, requestedDropoffLocation } = params;
            const { lat: requestedPickupLatitude, lng: requestedPickupLongitude } = requestedPickupLocation || ({} as LatLngLiteral);
            const { lat: requestedDropoffLatitude, lng: requestedDropoffLongitude } = requestedDropoffLocation || ({} as LatLngLiteral);

            this.axios
                .get<EstimateServiceDto[]>(`${AppConfig.apiRoutePrefix}/estimates/services`, {
                    params: {
                        requestedPickupLatitude,
                        requestedPickupLongitude,
                        requestedDropoffLatitude,
                        requestedDropoffLongitude
                    }
                })
                .then(response => {
                    // TODO: out of service area errors should be returned as Bad Request response
                    if (!response.data.length) {
                        return reject('errorMessages.noServiceFound');
                    }

                    // return the first item
                    resolve(response.data[0]);
                })
                .catch(e => {
                    if (isAxiosError(e)) {
                        const { response } = (e as AxiosError) || ({} as AxiosError);
                        const { status, data } = response || ({} as AxiosResponse);

                        let message: string;
                        switch (status) {
                            case 400:
                                message = 'errorMessages.outOfServiceArea';
                                break;
                            default: {
                                message = data || 'errorMessages.defaultErrorMessage';
                            }
                        }

                        reject({
                            status: status || 500,
                            message
                        });
                    } else {
                        reject(e);
                    }
                });
        });
    }

    getServices(status?: StatusDto, active?: boolean): Promise<ServiceDto[]> {
        return new Promise<ServiceDto[]>((resolve, reject) => {
            this.axios
                .get(`${AppConfig.apiRoutePrefix}/services`, {
                    params: {
                        status,
                        active
                    }
                })
                .then(response => {
                    resolve(response.data);
                })
                .catch(e => {
                    if (isAxiosError(e)) {
                        const { response } = (e as AxiosError) || ({} as AxiosError);
                        const { status, data } = response || ({} as AxiosResponse);

                        reject({
                            status: status || 500,
                            message: data || 'errorMessages.defaultErrorMessage'
                        });
                    } else {
                        reject(e);
                    }
                });
        });
    }
}
