/**
 * Converts array of address components to human-readable string.
 *
 * @param value {google.maps.GeocoderAddressComponent[]} Array of address components
 * @returns {string} Formatted address
 */
export function formatAddress(value: google.maps.GeocoderAddressComponent[]): string {
    if (!value || value.length === 0) return null;

    // falback
    const administrativeAreaLevels = value.filter(c => c.types.filter(t => t.startsWith('administrative_area_level_')).length !== 0);
    administrativeAreaLevels.sort((a, b) => {
        const _a = a.types.filter(__a => __a.startsWith('administrative_area_level_'))[0];
        const _b = b.types.filter(__b => __b.startsWith('administrative_area_level_'))[0];

        // reversed
        if (_a < _b) return 1;
        if (_a > _b) return -1;
        return 0;
    });

    let city = administrativeAreaLevels[0];

    // use locality when available
    const locality = value.filter(c => c.types.indexOf('locality') !== -1)[0];
    if (locality) city = locality;

    // use postal town when available
    const postalTown = value.filter(c => c.types.indexOf('postal_town') !== -1)[0];
    if (postalTown) city = postalTown;

    // if transit station, we use just the station name
    const transit_station = value.filter(c => c.types.indexOf('transit_station') !== -1)[0];
    if (transit_station) {
        return `${transit_station.long_name}, ${city.long_name}`;
    }

    // build street address
    const route = value.filter(c => c.types.indexOf('route') !== -1)[0];
    const streetNumber = value.filter(c => c.types.indexOf('street_number') !== -1)[0];

    const streetAddress = streetNumber ? `${route.long_name} ${streetNumber.long_name}, ` : route ? `${route.long_name}, ` : '';

    return `${streetAddress}${city.long_name}`;
}
