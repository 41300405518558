<template>
    <div class="overlay h-100 d-flex flex-column align-center">
        <div class="w-100 d-flex flex-column align-center py-6 px-3">
            <div class="page-content pointer-events-all mb-2 position-relative">
                <v-text-field
                    id="pickupLocationInput"
                    :model-value="requestedPickupAddress"
                    :label="t('labels.from')"
                    readonly
                    variant="solo"
                    color="#555"
                    bg-color="#eee"
                    rounded="0"
                    hide-details
                    class="elevation-3 v-text-field--no-bottom-borders v-text-field--no-border-radius"
                />
                <div style="position: absolute; left: 0; right: 0; top: 0; bottom: 0; cursor: pointer" @click="emit('nav', 'pickup')"></div>
            </div>
            <div class="page-content pointer-events-all position-relative">
                <v-text-field
                    id="dropoffLocationInput"
                    :model-value="requestedDropoffAddress"
                    :label="t('labels.to')"
                    readonly
                    variant="solo"
                    color="#555"
                    bg-color="#eee"
                    rounded="0"
                    hide-details
                    class="elevation-3 v-text-field--no-bottom-borders v-text-field--no-border-radius"
                />
                <div
                    style="position: absolute; left: 0; right: 0; top: 0; bottom: 0; cursor: pointer"
                    @click="emit('nav', 'dropoff')"
                ></div>
            </div>
        </div>
        <v-spacer />
        <v-card elevation="3" rounded="0" class="w-100 pointer-events-all">
            <v-card-text class="d-flex flex-column align-center py-6 px-4">
                <div class="page-content">
                    <v-row dense>
                        <v-col cols="6" md="auto" class="flex-grow-1">
                            <div v-if="timeRules" class="h-100 d-flex align-end">
                                <v-radio-group
                                    id="pickupTypeSelector"
                                    v-model.number="requestedPickupTsType"
                                    density="comfortable"
                                    hide-details
                                >
                                    <v-radio id="pickupTypeSelector_leaveNow" :value="0" color="primary" class="trip-details__radio">
                                        <template #label>
                                            <div class="ml-2 text-black">
                                                {{ t('buttons.leaveNow') }}
                                            </div>
                                        </template>
                                    </v-radio>
                                    <v-radio id="pickupTypeSelector_leaveLater" :value="1" color="primary" class="trip-details__radio">
                                        <template #label>
                                            <div class="ml-2 text-black">
                                                {{ t('buttons.leaveLater') }}
                                            </div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                            </div>
                        </v-col>
                        <v-spacer />
                        <v-col cols="6" md="auto" class="flex-shrink-1">
                            <p id="numRiders__label" class="caption text-uppercase text-grey mb-2 text-center">
                                {{ t('labels.numRiders') }}
                            </p>
                            <number-input
                                id="numRidersInput"
                                v-model="numRiders"
                                :max="maxNumRiders"
                                :increase-btn-label="t('buttons.increase')"
                                :decrease-btn-label="t('buttons.decrease')"
                                aria-labelledby="numRiders__label"
                            />
                        </v-col>
                    </v-row>
                    <div v-if="requestedPickupTsType === 1">
                        <div class="py-3">
                            <p id="requestedPickupTs__label" class="caption text-uppercase text-grey mb-2">
                                {{ t('labels.requestedPickupTs') }}
                            </p>
                            <datetime-picker
                                id="requestedPickupTs"
                                v-model="requestedPickupTs"
                                :min="minRequestedPickupTs"
                                :max="maxRequestedPickupTs"
                                :locale="locale"
                                :time-rules="timeRules"
                                aria-labelledby="requestedPickupTs__label"
                            />
                        </div>
                        <div class="text-grey">
                            <div>{{ t('labels.open') }}</div>
                            <div v-for="time in openingTimes" :key="time.days">
                                <span>{{ DayFilter(time.days) }}</span>
                                <template v-for="(i, idx) in Math.ceil(time.timeRules.length / 2)"
                                    >&nbsp;{{ TimeFilter(time.timeRules[idx * 2]) }}-{{ TimeFilter(time.timeRules[idx * 2 + 1]) }}</template
                                >
                            </div>
                        </div>
                    </div>
                    <div class="mt-4">
                        <v-btn
                            id="nextButton"
                            :loading="isLoading"
                            :disabled="isLoading || !isValid"
                            color="primary"
                            rounded
                            block
                            size="large"
                            :text="t('buttons.next')"
                            class="mb-2"
                            @click="next"
                        />
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script lang="ts" setup>
import AppConfig from '@/AppConfig';
import { RequestParameters } from '@/Models/RequestParameters';
import moment from 'moment';
import 'moment-timezone';
import { adjustTimeWithinOperatingHours } from '@/Helpers/OperatingHoursHelpers';
import { RequestedPickupTsType } from '@/Models/RequestedPickupTsType';
import DatetimePicker from '@/components/DatetimePicker.vue';
import NumberInput from '@/components/NumberInput.vue';
import { ServiceTimeRulesDto } from '@/Dto/ServiceTimeRulesDto';
import { TimeRuleDaysDto } from '@/EnumsDto/TimeRuleDaysDto';
import { ServiceDto } from '@/Dto/ServiceDto';
import { useI18n } from 'vue-i18n';
import { nextTick, ref, computed } from 'vue';
import { DayFilter, TimeFilter } from '@/Filters/DateFilters';
import i18n from '@/Plugins/i18n';

const { t } = useI18n();

interface Props {
    modelValue: RequestParameters;
    isLoading: boolean;
}
interface Emits {
    (e: 'update:modelValue', value: RequestParameters): void;
    (e: 'update:estimate', value: null | any): void;
    (e: 'update:request-estimates', value: null | any): void;
    (e: 'nav:next'): void;
    (e: 'map:fitTrip', value: any): void;
    (e: 'nav', value: string): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const numRiders = ref(1);
const requestedPickupTs = ref(moment().tz(AppConfig.timezone));
const minRequestedPickupTs = ref(moment().tz(AppConfig.timezone));
const maxRequestedPickupTs = ref(moment().tz(AppConfig.timezone).add(AppConfig.maxDaysInTheFuture, 'days'));
const requestedPickupTsType = ref(RequestedPickupTsType.Now);

const interval = ref();
const timeout = ref();

const locale = computed(() => i18n.global.locale.value);

const timeRules = computed((): ServiceTimeRulesDto[] => {
    const { service: { timeRules = [] } = {} } = props.modelValue;
    // sort so that general time rules (Weekdays, Weekends, etc) come first
    return timeRules.sort((a, b) => (a.days > b.days ? -1 : 1));
});

const openingTimes = computed(() => {
    if (!timeRules.value) return null;

    const today = moment().tz(AppConfig.timezone).startOf('day');

    return timeRules.value
        .reduce((acc, curr) => {
            // get Moment instance for start and end times
            const startTime = today.clone().add(curr.startTs, 's');
            const endTime = today.clone().add(curr.endTs, 's');
            const key = curr.days;

            // check if we already have the day in the array
            const index = acc.findIndex(x => x.days === key);

            if (index > -1) {
                // extend existing timeRules
                acc[index].timeRules = acc[index].timeRules.concat(startTime, endTime).sort();
            } else {
                // add new object
                acc.push({
                    days: key,
                    timeRules: [startTime, endTime]
                });
            }

            return acc;
        }, [])
        .sort((a, b) => dayToNumber(a.days) - dayToNumber(b.days));
});

const maxNumRiders = computed(() => {
    const { service: { organization: { maxNumRider = 6 } = {} } = {} } = props.modelValue;
    return maxNumRider ? maxNumRider : AppConfig.maxNumRiders;
});

const isValid = computed(() => {
    if (!props.modelValue) return false;
    const { requestedPickupLocation, requestedDropoffLocation } = props.modelValue;
    return !!requestedPickupLocation && !!requestedDropoffLocation;
});

const requestedPickupAddress = computed(() => props.modelValue.requestedPickupAddress);
const requestedDropoffAddress = computed(() => props.modelValue.requestedDropoffAddress);

function init() {
    minRequestedPickupTs.value = adjustTimeWithinOperatingHours(minRequestedPickupTs.value, timeRules.value);

    if (props.modelValue) {
        const { requestedPickupTs: rpt, requestedPickupTsType: rptt, numRiders: nr, service } = props.modelValue;

        requestedPickupTs.value = adjustTimeWithinOperatingHours(rpt ? rpt : requestedPickupTs.value, timeRules.value);

        if (requestedPickupTsType.value) requestedPickupTsType.value = rptt;

        if (typeof numRiders.value === 'number') numRiders.value = nr;

        if (service) {
            const { maxAdvanceBookingDays } = service as ServiceDto;

            if (typeof maxAdvanceBookingDays === 'number')
                maxRequestedPickupTs.value = moment().tz(AppConfig.timezone).add(maxAdvanceBookingDays, 'days');
        }
    }

    // reset estimate
    emit('update:estimate', null);
    emit('update:request-estimates', null);

    window.addEventListener('resize', debouncedFitTrip);
    debouncedFitTrip();

    // check the requestedPickupTs every second to make sure
    // it is still ahead of the current time
    interval.value = setInterval(() => syncTime(), 1000);
    syncTime();
}

function destroy() {
    clearInterval(interval.value);
    window.removeEventListener('resize', debouncedFitTrip);
}

function debouncedFitTrip() {
    clearTimeout(timeout.value);
    timeout.value = setTimeout(fitTrip, 250);
}

function fitTrip() {
    nextTick(() => {
        // we need to make sure the total padding does not exceed the available space
        const vPadding = Math.min((window.innerHeight * 0.8) / 2, 250);

        emit('map:fitTrip', {
            paddingTopLeft: [50, vPadding],
            paddingBottomRight: [50, vPadding],
            noMoveStart: true
        });
    });
}

function syncTime() {
    const currentTime = moment().tz(AppConfig.timezone).add(1, 'm');

    if (requestedPickupTs.value.isBefore(currentTime, 'm')) {
        requestedPickupTs.value = currentTime;
    }

    if (minRequestedPickupTs.value.isBefore(currentTime, 'm')) {
        minRequestedPickupTs.value = currentTime;
    }
}

async function next() {
    if (!isValid.value) return;

    emit('update:modelValue', {
        ...props.modelValue,
        requestedPickupTs: requestedPickupTs.value,
        requestedPickupTsType: requestedPickupTsType.value,
        numRiders: numRiders.value
    });
    emit('nav:next');
}

function dayToNumber(days: TimeRuleDaysDto) {
    if (days >= TimeRuleDaysDto.Mondays && days <= TimeRuleDaysDto.Fridays) {
        return 1;
    }

    if (days === TimeRuleDaysDto.Weekdays) {
        return 2;
    }

    if (days === TimeRuleDaysDto.Saturdays || days === TimeRuleDaysDto.Sundays || days === TimeRuleDaysDto.Weekends) {
        return 3;
    }

    return 0;
}

defineExpose({ init, destroy });
</script>

<style lang="scss">
.trip-details__radio {
    --v-medium-emphasis-opacity: 1;

    &.v-selection-control--dirty .v-selection-control__input > .v-icon {
        color: rgb(var(--v-theme-primary));
    }
}
</style>
