<template>
    <div class="h-100 d-flex align-center justify-center">
        <div class="page-content ma-4">
            <h1 v-if="message" class="display-1 text-center mb-12">{{ message }}</h1>
            <h1 v-else>{{ t('errorMessages.defaultErrorMessage') }}</h1>
            <v-btn :to="{ name: 'home' }" color="primary" rounded block size="large">
                {{ t('buttons.newRequest') }}
            </v-btn>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

interface Props {
    message?: string;
}
defineProps<Props>();

const { t } = useI18n();
</script>
