import { PickupDropoffProps } from '@/Models/PickupDropoff';
import { IGeolocationService, IGeolocationServiceId } from '@/Services/IGeolocationService';
import { MapLocation, SetMapViewArgs } from '@/Models/MapLocation';
import { LatLngLiteral, LatLng } from 'leaflet';
import { ref, watch, inject } from 'vue';
import { useContainer } from '@/Plugins/inversify';
import { BookingPageProvideKey } from '@/InjectionKeys';
import LocationInput from '@/components/LocationInput.vue';

export interface Emits {
    (e: 'map:setView', value: SetMapViewArgs): void;
    (e: 'update:user-position', value: GeolocationPosition): void;
    (e: 'update:is-loading', value: boolean): void;
}

export function usePickupDropoff(props: PickupDropoffProps, emit: Emits) {
    const container = useContainer();
    const geolocation = container.get<IGeolocationService>(IGeolocationServiceId);
    const { map, center, zoom, bounds } = inject(BookingPageProvideKey);

    const locationInputRef = ref<InstanceType<typeof LocationInput>>();

    const mapLocation = ref<MapLocation | null>(null);
    let skipSetCenter = false;
    let userMove = false;
    let timeout = null;

    const isValid = (isInServiceArea: boolean) => {
        if (!mapLocation.value) return false;

        const { address, location, place_id } = mapLocation.value;
        return !!address && !!location && !!place_id && isInServiceArea;
    };

    function addEventListeners() {
        if (!map.value?.leafletObject) return;

        // set up map event handlers
        map.value.leafletObject.on('movestart', onMapMoveStart);
    }

    function removeEventListeners() {
        if (!map.value?.leafletObject) return;

        // remove map event handlers
        map.value.leafletObject.off('movestart', onMapMoveStart);
    }

    function onInput() {
        setLoading(false);
    }

    function onInputUserSelected(value: MapLocation) {
        mapLocation.value = value;
        if (!value) return;

        // set skip address lookup flag
        skipSetCenter = true;
        mapZoomTo(value.location);
    }

    function setLocationInputCenter(value: LatLng | LatLngLiteral) {
        if (skipSetCenter) {
            skipSetCenter = false;
            setLoading(false);
            return;
        }

        if (locationInputRef.value) locationInputRef.value.setCoordinates(value);
    }

    function onMapMoveStart() {
        clearTimeout(timeout);

        if (!props.watchCenter) return;
        userMove = true;
        setLoading(true);
    }

    function onMapBoundsChanged() {
        clearTimeout(timeout);
        if (!userMove || !props.watchCenter) return;

        // set loading state to true to prevent the brief awkward moment while timeout is counting down
        if (locationInputRef.value) locationInputRef.value.setLoading(true);

        timeout = setTimeout(() => {
            userMove = false;
            setLocationInputCenter(center.value);

            // reset loading state
            if (locationInputRef.value) locationInputRef.value.setLoading(false);
        }, 800);
    }

    function onGeolocateInput(value: GeolocationPosition) {
        const { latitude: lat, longitude: lng } = value.coords;
        setLocationInputCenter({ lat, lng });
        afterOnGeolocateInput(value);
    }

    function setLoading(value: boolean) {
        onSetLoading(value);
    }

    async function mapZoomTo(location: LatLngLiteral, noMoveStart = true) {
        await onMapZoomTo(location, noMoveStart);
    }

    function afterOnGeolocateInput(value: GeolocationPosition) {
        const { latitude: lat, longitude: lng } = value.coords;
        emit('update:user-position', value);
        emit('map:setView', { center: { lat, lng }, zoom: 17, options: { animate: true } });
    }

    function onSetLoading(value: boolean) {
        emit('update:is-loading', value);
    }

    function onMapZoomTo(location: LatLngLiteral, noMoveStart = true) {
        return new Promise<void>(resolve =>
            map.value.leafletObject.once('moveend zoomend', () => resolve()).setView(location, 17, { animate: true, noMoveStart })
        );
    }

    watch(
        () => map.value?.leafletObject,
        value => {
            if (value) {
                addEventListeners();
            } else {
                removeEventListeners();
            }
        },
        {
            immediate: true
        }
    );

    watch(bounds, onMapBoundsChanged, { deep: true });

    return {
        locationInputRef,
        isValid,
        mapLocation,
        onInput,
        onInputUserSelected,
        onGeolocateInput,
        mapZoomTo,
        setLocationInputCenter,
        geolocation,
        map,
        center,
        zoom,
        bounds
    };
}
