<template>
    <v-container class="h-100" style="padding: 0">
        <component :is="pageComponent" v-bind="componentProps" @error="showStatusPage" />
    </v-container>
</template>

<script lang="ts" setup>
import { onMounted, ref, inject, watch, shallowRef } from 'vue';
import Status from './Status.vue';
import RequestDetails from './RequestDetails.vue';
import { useI18n } from 'vue-i18n';
import { LoadingOverlayProvideKey } from '@/InjectionKeys';
import { useOnline } from '@vueuse/core';

interface Props {
    id: string;
    companyId: string;
    phoneNumber?: string;
}

const props = defineProps<Props>();
const { hideOverlay } = inject(LoadingOverlayProvideKey);

const pageComponent = shallowRef(null);
const componentProps = ref(null);

const { t } = useI18n();
const online = useOnline();

onMounted(() => showRequestDetails(props.companyId, props.id, !!props.phoneNumber));

watch(online, isOnline => {
    if (isOnline) {
        // on online, restart request details
        showRequestDetails(props.companyId, props.id);
    } else {
        // on offline, show status page
        showStatusPage(t('messages.deviceOffline'));
    }
});

function showRequestDetails(companyId: string, id: string, showThankyou?: boolean) {
    pageComponent.value = RequestDetails;

    componentProps.value = { companyId, id, showThankyou: true };
}

function showStatusPage(message: string) {
    hideOverlay();
    pageComponent.value = Status;

    componentProps.value = { message };
}
</script>
