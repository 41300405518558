declare global {
    interface Window {
        dataLayer: any;
    }
}

/**
 * Pushes the given object to the GTM dataLayer.
 *
 * @param {Object} value Object to push into dataLayer
 */
export function pushToDataLayer(value: { [key: string]: any }) {
    if (!window.dataLayer || !value) return;

    window.dataLayer.push(value);
}

/**
 * Resets all dataLayer properties used by HentMeg.
 */
export function resetDataLayer() {
    pushToDataLayer({ event: 'app.reset' });
}
