import { Moment, unitOfTime } from 'moment';
import { AbstractDayTimeRuleToDays, TimeRuleDaysDto } from '@/EnumsDto/TimeRuleDaysDto';
import { ServiceTimeRulesDto } from '@/Dto/ServiceTimeRulesDto';

/**
 * Tests the given value against an array of service time rules to determine its validity.
 *
 * @param value Moment instance to test
 * @param timeRules Array of ServiceTimeRulesDto
 * @param granularity Granularity level to apply when testing
 */
export function isWithinOperatingHours(value: Moment, timeRules: ServiceTimeRulesDto[], granularity?: unitOfTime.StartOf) {
    // check for invalid inputs
    if (!value || !timeRules) {
        return false;
    }

    // if no timeRules, return true
    if (timeRules.length === 0) {
        return true;
    }

    // we check against timeRules
    const day = value.isoWeekday();
    const midnight = value.clone().startOf('day');

    for (let i = 0; i < timeRules.length; i++) {
        const timeRule = timeRules[i];

        if (!timeRule) continue;

        // if we have an exact day match, or the day is included in AbstractDayTimeRuleToDays (i.e. Weekdays)
        if (
            timeRule.days === day ||
            (timeRule.days > TimeRuleDaysDto.Sundays && AbstractDayTimeRuleToDays[timeRule.days].indexOf(day) > -1)
        ) {
            // for days, we can stop here
            if (!granularity) {
                return true;
            }

            // else validate time with the given granularity
            const startTime = midnight.clone().add(timeRule.startTs, 's');
            const endTime = midnight.clone().add(timeRule.endTs, 's');

            if (value.isSameOrAfter(startTime, granularity) && value.isBefore(endTime, granularity)) {
                return true;
            }
        }
    }

    return false;
}

// for vee-validate
export default {
    validate(value: Moment, timeRules: ServiceTimeRulesDto[]) {
        return isWithinOperatingHours(value, timeRules, 'm');
    }
};
