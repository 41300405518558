/**
 * Returns the property at the given property path.
 *
 * @param input Input object
 * @param path Property path (dot-separated)
 */
export function getByKey(input: Object, path: string) {
    if (!input || !path) return null;

    path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    path = path.replace(/^\./, ''); // strip a leading dot
    const a = path.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i];
        if (k in input) {
            input = input[k];
        } else {
            return null;
        }
    }
    return input;
}
