import { InjectionKey, Ref } from 'vue';
import { AppConfigDto } from './Dto/AppConfigDto';
import { LMap } from '@vue-leaflet/vue-leaflet';
import { LatLngBounds, LatLngLiteral } from 'leaflet';

interface LoadingOverlayProvide {
    isVisible: Ref<boolean>;
    showOverlay: (msg?: string) => void;
    hideOverlay: () => void;
}

interface BookingPageProvide {
    map: Ref<InstanceType<typeof LMap>>;
    center: Ref<LatLngLiteral>;
    zoom: Ref<number>;
    bounds: Ref<LatLngBounds>;
}

export const AppConfigProvideKey: InjectionKey<Ref<AppConfigDto>> = Symbol.for('app:appConfig');
export const LoadingOverlayProvideKey: InjectionKey<LoadingOverlayProvide> = Symbol.for('app:loadingOverlay');
export const BookingPageProvideKey: InjectionKey<BookingPageProvide> = Symbol.for('app:bookingPage');
