<template>
    <div class="overlay py-6 px-3 h-100 d-flex flex-column align-center" :class="{ 'overlay__icon overlay__icon--dropoff': !hasValue }">
        <div class="page-content pointer-events-all mb-2 position-relative">
            <v-text-field
                id="pickupLocationInput"
                :model-value="requestedPickupAddress"
                :label="t('labels.from')"
                readonly
                variant="solo"
                color="#555"
                bg-color="#eee"
                rounded="0"
                hide-details
                class="elevation-3 v-text-field--no-bottom-borders v-text-field--no-border-radius"
            />
            <div style="position: absolute; left: 0; right: 0; top: 0; bottom: 0; cursor: pointer" @click="emit('nav', 'pickup')"></div>
        </div>
        <div class="page-content pointer-events-all">
            <location-input
                id="dropoffLocationInput"
                ref="locationInputRef"
                v-model="mapLocation"
                :label="t('labels.to')"
                class="elevation-3"
                @update:model-value="onInput"
                @input:user-selected="onInputUserSelected"
            />
        </div>
        <v-spacer />
        <div class="page-content mb-2">
            <div class="d-flex flex-column align-end justify-end">
                <div v-if="infoBtnLinkUrl" class="pointer-events-all">
                    <v-btn icon color="#fff" class="ma-0 mb-2 round-btn" :href="sanitizeUrl(infoBtnLinkUrl)" target="_blank">
                        <v-img src="/icons/info-icon.svg" width="32" height="32" alt="info" />
                    </v-btn>
                </div>
                <div class="pointer-events-all">
                    <geolocate-button
                        v-if="geolocation.isAvailable"
                        ref="geolocateBtnRef"
                        :geolocation="geolocation"
                        class="ma-0"
                        @input="onGeolocateInput"
                    />
                </div>
            </div>
        </div>
        <div class="page-content">
            <div class="pointer-events-all">
                <v-btn
                    id="nextButton"
                    :loading="isLoading"
                    :disabled="isLoading || !isValid(isInServiceArea)"
                    color="primary"
                    rounded
                    block
                    size="large"
                    @click="setLocation"
                >
                    <template v-if="mapLocation && !isInServiceArea">{{ t('labels.outOfServiceArea') }}</template>
                    <template v-else>{{ t('buttons.dropMeOffHere') }}</template>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { PickupDropoffProps } from '@/Models/PickupDropoff';
import GeolocateButton from '@/components/GeolocateButton.vue';
import LocationInput from '@/components/LocationInput.vue';
import { Point } from 'leaflet';
import { RequestParameters } from '@/Models/RequestParameters';
import { inside } from '@/Helpers/VueGmapHelpers';
import { ServiceDto } from '@/Dto/ServiceDto';
import { EstimateServiceDto } from '@/Dto/EstimateServiceDto';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { usePickupDropoff, Emits as BaseEmits } from './usePickupDropoff';
import { SetMapViewArgs } from '@/Models/MapLocation';
import { useVModel } from '@vueuse/core';
import { nextTick } from 'vue';
import { sanitizeUrl } from '@braintree/sanitize-url';

interface Emits extends BaseEmits {
    (e: 'update:modelValue', value: RequestParameters): void;
    (e: 'map:setView', value: SetMapViewArgs): void;
    (e: 'nav:next'): void;
    (e: 'nav', value: string): void;
}

const props = defineProps<PickupDropoffProps>();
const emit = defineEmits<Emits>();
const { t } = useI18n();

const _modelValue = useVModel(props, 'modelValue');
const offset = [100, -100];
const geolocateBtnRef = ref<InstanceType<typeof GeolocateButton>>();

const requestedPickupAddress = computed(() => props.modelValue.requestedPickupAddress);

const {
    locationInputRef,
    isValid,
    mapLocation,
    onInput,
    onInputUserSelected,
    onGeolocateInput,
    mapZoomTo,
    setLocationInputCenter,
    geolocation,
    map
} = usePickupDropoff(props, emit);

const service = computed((): ServiceDto => {
    const { service } = props.modelValue || ({} as RequestParameters);
    const { id } = service || ({} as EstimateServiceDto);
    if (!id) return null;

    return props.services.find(s => s.id === id);
});

const isInServiceArea = computed(() => {
    const { dropoffZones = null } = service.value || {};
    const { location = null } = mapLocation.value || {};
    if (!location || !dropoffZones || dropoffZones.length === 0) return false;

    const { lat, lng } = location;
    return inside(
        { lat, lng },
        dropoffZones[0].area.coordinates[0].map(c => ({ lat: c[1], lng: c[0] }))
    );
});

const hasValue = computed(() => {
    const { requestedDropoffAddress } = props.modelValue || ({} as RequestParameters);
    return !!requestedDropoffAddress;
});

async function init() {
    const {
        requestedDropoffAddress: address,
        requestedDropoffLocation: location,
        requestedDropoffPlaceId: place_id,
        requestedPickupLocation
    } = props.modelValue || {};

    if (address && location && place_id) {
        mapLocation.value = {
            address,
            location,
            place_id
        };

        // focus on selected location
        await mapZoomTo(location);

        nextTick(
            () =>
                (_modelValue.value = {
                    ..._modelValue.value,
                    requestedDropoffAddress: undefined,
                    requestedDropoffLocation: undefined,
                    requestedDropoffPlaceId: undefined
                })
        );
    } else if (requestedPickupLocation && map.value) {
        // calculate offset
        // ref: https://stackoverflow.com/questions/29080194/leaflet-adding-an-offset-to-marker
        const { lat, lng } = requestedPickupLocation;
        const point = map.value.leafletObject.latLngToContainerPoint({ lat, lng });
        const offsetPoint = new Point(point.x + offset[0], point.y + offset[1]);
        const offsetLatLng = map.value.leafletObject.containerPointToLatLng(offsetPoint);

        setLocationInputCenter(offsetLatLng);
        emit('map:setView', { center: offsetLatLng, zoom: map.value.leafletObject.getZoom() });
    }
}

function setLocation() {
    const { address: requestedDropoffAddress, location: requestedDropoffLocation, place_id: requestedDropoffPlaceId } = mapLocation.value;

    _modelValue.value = {
        ...props.modelValue,
        requestedDropoffAddress,
        requestedDropoffLocation,
        requestedDropoffPlaceId
    };
    emit('nav:next');
}

defineExpose({ init });
</script>
