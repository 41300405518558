import { Point, LineString } from 'geojson';

export function LineStringToLatLngLiteralArray(lineString: LineString): Array<{ lat: number; lng: number }> {
    return lineString
        ? lineString.coordinates.map(c => ({
              lat: Number(c[1]),
              lng: Number(c[0])
          }))
        : null;
}

export function PointToLatLng(point: Point): { lat: number; lng: number } {
    if (!point) return null;
    const { coordinates } = point;
    const [lng, lat] = coordinates;
    return { lat, lng };
}
