import { EstimateServiceDto } from '@/Dto/EstimateServiceDto';
import { ServiceDto } from '@/Dto/ServiceDto';
import { StatusDto } from '@/EnumsDto/StatusDto';
import { RequestParameters } from '@/Models/RequestParameters';

export const IServiceServiceId = Symbol.for('IServiceService');

export interface IServiceService {
    /**
     * Fetches a service estimate.
     *
     * @param params Request parameters
     */
    getServiceEstimate(params: RequestParameters): Promise<EstimateServiceDto>;

    /**
     * Fetches a list of services.
     *
     * @param status Status of services to fetch
     */
    getServices(status?: StatusDto, active?: boolean): Promise<ServiceDto[]>;
}
