export enum RequestStatusDto {
    Processing = 10,
    ServiceDisruption = 15,
    NoDriversAvailable = 20,
    Accepted = 30,
    Arriving = 35,
    InProgress = 40,
    Cancelled = 50,
    Completed = 60
}
