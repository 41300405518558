import { RouterView, createRouter, createWebHistory } from 'vue-router';
import BookingPage from '@/Routes/BookingPage/BookingPage.vue';
import RequestDetailsPage from '@/Routes/RequestDetailsPage/RequestDetailsPage.vue';
import { routeMiddleware } from '@/Plugins/i18n';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/:locale?',
            component: RouterView,
            children: [
                {
                    name: 'home',
                    path: '',
                    component: BookingPage
                },
                {
                    name: 'request-details',
                    path: ':companyId/:id',
                    component: RequestDetailsPage,
                    props: true
                }
            ]
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: '/'
        }
    ]
});

router.beforeEach(routeMiddleware);

export default router;
