<template>
    <l-feature-group v-if="modelValue" pane="markerPane">
        <l-circle-marker
            :lat-lng="center"
            :z-index-offset="zIndexOffset"
            :stroke="circleMarkerOptions.stroke"
            :color="circleMarkerOptions.color"
            :weight="circleMarkerOptions.weight"
            :fill="circleMarkerOptions.fill"
            :fill-color="circleMarkerOptions.fillColor"
            :fill-opacity="circleMarkerOptions.fillOpacity"
        />
        <l-circle
            :lat-lng="center"
            :z-index-offset="zIndexOffset"
            :color="circleOptions.color"
            :opacity="circleOptions.opacity"
            :fill-color="circleOptions.fillColor"
            :fill-opacity="circleOptions.fillOpacity"
            :radius="circleOptions.radius"
        />
    </l-feature-group>
</template>

<script lang="ts" setup>
import { LFeatureGroup, LCircleMarker, LCircle } from '@vue-leaflet/vue-leaflet';
import L, { CircleMarkerOptions } from 'leaflet';
import { computed } from 'vue';

interface Props {
    modelValue: GeolocationPosition;
    zIndexOffset?: number;
}

const props = defineProps<Props>();

const center = computed(() => {
    if (!props.modelValue) return null;
    const { latitude, longitude } = props.modelValue.coords;
    return L.latLng(latitude, longitude);
});

const circleOptions = computed((): CircleMarkerOptions => {
    return {
        color: '#d9e7fd',
        opacity: 0.8,
        fillColor: '#d9e7fd',
        fillOpacity: 0.7,
        radius: props.modelValue.coords.accuracy
    };
});

const circleMarkerOptions = computed((): CircleMarkerOptions => {
    return {
        stroke: true,
        color: '#fff',
        weight: 2,
        fill: true,
        fillColor: '#458ce4',
        fillOpacity: 1
    };
});
</script>
