<template>
    <v-app>
        <v-main>
            <router-view v-if="ready" @error="onError" />
            <error-dialog v-model="showErrorDialog" v-model:error="errorMessage" />
            <loading-overlay v-model="overlayVisibility" :message="overlayMessage" />
        </v-main>
    </v-app>
</template>

<script lang="ts" setup>
import 'leaflet/dist/leaflet.css';
import { ref, onMounted, onBeforeUnmount, provide } from 'vue';
import ErrorDialog from '@/components/core/ErrorDialog.vue';
import LoadingOverlay from './components/LoadingOverlay.vue';
import { AppConfigProvideKey, LoadingOverlayProvideKey } from '@/InjectionKeys';
import { useContainer } from './Plugins/inversify';
import { IConfigService, IConfigServiceId } from './Services/IConfigService';
import { AppConfigDto } from './Dto/AppConfigDto';
import { Loader, type LoaderOptions } from '@googlemaps/js-api-loader';
import { setupMomentLocale } from '@/Plugins/moment';
import { useI18n } from 'vue-i18n';

const container = useContainer();
const appConfigService = container.get<IConfigService>(IConfigServiceId);
const i18n = useI18n();

const ready = ref(false);
const appConfig = ref<AppConfigDto>({});
const showErrorDialog = ref(false);
const errorMessage = ref();

let resizeTimeout = null;

const overlayMessage = ref('');
const overlayVisibility = ref(false);

function showOverlay(msg?: string) {
    overlayMessage.value = msg;
    overlayVisibility.value = true;
}

function hideOverlay() {
    overlayMessage.value = '';
    overlayVisibility.value = false;
}

provide(AppConfigProvideKey, appConfig);
provide(LoadingOverlayProvideKey, { showOverlay, hideOverlay, isVisible: overlayVisibility });

// created
showOverlay();

// Ref: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
onMounted(async () => {
    hideOverlay();
    resize();

    // fetch app config
    appConfig.value = await appConfigService.getConfig();

    // set up google.maps
    if (!window.google?.maps) {
        const loaderOptions: LoaderOptions = { apiKey: appConfig.value.gmapsApiKey || '', libraries: ['places', 'geometry'] };
        const loader = new Loader(loaderOptions);

        await loader.importLibrary('maps');
    }

    // We listen to the resize event
    window.addEventListener('resize', debouncedResize);

    // setup moment locale
    setupMomentLocale(i18n);

    ready.value = true;
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', debouncedResize);
});

function onError(error) {
    errorMessage.value = error;
    showErrorDialog.value = true;
}

function resize() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

function debouncedResize() {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(() => resize(), 250);
}
</script>

<style lang="scss">
@import '@/Styles/styles.scss';

// Prevents iOS Safari overscroll effect
// Ref: https://www.bram.us/2016/05/02/prevent-overscroll-bounce-in-ios-mobilesafari-pure-css/
html,
body {
    position: fixed;
    overflow: hidden;
    height: 100%;
}

.v-application--wrap {
    z-index: 200;
    min-height: -webkit-fill-available;
}

.v-application {
    // Restores scrollability
    overflow-y: auto;
    height: 100%;
    width: 100vw;
    -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
    font-family: 'Open Sans', sans-serif !important;
}

.v-btn {
    text-transform: none;
    font-size: 16px;
    letter-spacing: initial;

    &.v-size--large {
        font-size: 16px;
    }
}

.v-btn--fab.v-size--default.round-btn {
    height: 50px;
    width: 50px;
}

.v-btn--block {
    margin: 6px 0;
}

.v-radio .v-icon {
    color: rgba(0, 0, 0, 0.54);
}

.btn-num-input {
    margin: 6px 8px;

    &.v-btn--fab.v-size--small .v-icon {
        font-size: 18px;
    }
}

.dark-text {
    color: rgba(0, 0, 0, 0.87) !important;
}

.lmap {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.title,
.display-1,
.display-2,
.caption {
    font-family: 'Open Sans', sans-serif !important;
}

.title {
    font-size: 1.25rem !important;
    font-weight: 500;
    letter-spacing: 0.0125em !important;
    line-height: 2rem;
}

.caption {
    font-size: 0.75rem !important;
    letter-spacing: 0.0333333333em !important;
    line-height: 1.5;
}

.text-link {
    color: #36a14b;
}

.font-weight-semibold {
    font-weight: 600;
}
</style>
