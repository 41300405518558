import { RequestEstimate } from '@/Models/RequestEstimate';
import { CreateRequestResponseDto } from '@/Dto/CreateRequestResponseDto';
import { Request } from '@/Models/Request';
import { VehicleLocationDto } from '@/Dto/VehicleLocationDto';
import { RequestParameters } from '@/Models/RequestParameters';

export const IRequestServiceId = Symbol.for('IRequestService');

export interface IRequestService {
    /**
     * Fetches an estimation for the next available trip.
     *
     * @param params Request parameters
     */
    getNextAvailableRequestEstimate(params: RequestParameters): Promise<RequestEstimate>;

    /**
     * Fetches single or multiple estimations for a specific trip.
     *
     * @param params Request parameters
     */
    getRequestEstimates(params: RequestParameters): Promise<RequestEstimate | RequestEstimate[]>;

    /**
     * Creates a Request.
     *
     * @param params Request parameters
     */
    createRequest(params: RequestParameters): Promise<CreateRequestResponseDto>;

    /**
     * Fetches a specific Request.
     *
     * @param id Request ID
     */
    getRequest(companyId: string, id: string): Promise<Request>;

    /**
     * Cancels a specific Request.
     *
     * @param id Request ID
     */
    cancelRequest(companyId: string, id: string): Promise<void>;

    /**
     * Fetches the vehicle location for a Request.
     *
     * @param id Request ID
     */
    getVehicleLocation(companyId: string, id: string): Promise<VehicleLocationDto>;
}
