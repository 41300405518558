export default {
    apiRoutePrefix: '/api/v1.0',
    mapCenter: { lat: 65.57982867263381, lng: 16.89278813689722 },
    mapZoom: 5,
    timezone: 'Europe/Oslo',
    dateFormat: 'DD.MM.YYYY', // moment format
    timeFormat: 'HH:mm', // moment format
    luxonDateFormat: 'dd.MM.yyyy',
    luxonTimeFormat: 'HH:mm',
    luxonTimezone: 'Europe/Oslo',
    requestedFlexibility: 1200, // 20 minutes
    requestStatusUpdateInterval: 30000,
    vehicleLocationUpdateInterval: 5000,
    maxNumRiders: 6,
    vehicleLocationMinTime: 600, // 10 minutes
    showThankyouTimeout: 5000, // 5 seconds
    showPickupPointDelay: 2000, // 2 seconds
    maxDaysInTheFuture: 30 // Max number of days user can book for
};
