import '@mdi/font/css/materialdesignicons.css';
import { createApp } from 'vue';
import vuetify from '@/Plugins/vuetify';
import router from '@/Routes';
import App from './App.vue';
import '@/Plugins/moment';
import i18n from '@/Plugins/i18n';
import { createContainer } from '@/Plugins/inversify';

createApp(App).use(createContainer()).use(vuetify).use(router).use(i18n).mount('#app');
