import { ThemeDefinition, createVuetify } from 'vuetify';
import 'vuetify/styles';

const customTheme: ThemeDefinition = {
    dark: false,
    colors: {
        primary: '#36a14b',
        error: '#a94442'
    }
};

export default createVuetify({
    theme: {
        defaultTheme: 'customTheme',
        themes: {
            customTheme
        }
    }
});
