<template>
    <v-card>
        <v-card-text class="d-flex justify-center pa-4">
            <div class="page-content">
                <div class="d-flex flex-column">
                    <div>
                        <p class="text-grey caption text-uppercase mb-0">
                            {{ heading }}
                        </p>
                        <template v-if="requestEstimates">
                            <v-radio-group id="requestEstimateSelector" :value="estimate" hide-details @change="onSelectedEstimateChange">
                                <v-radio
                                    v-for="requestEstimate in requestEstimates"
                                    :key="requestEstimate.requestedPickupTs.unix()"
                                    :value="requestEstimate"
                                    :value-comparator="estimateComparator"
                                    color="primary"
                                >
                                    <template #label>
                                        <span class="font-weight-semibold text-black">
                                            {{ timeString(requestEstimate) }}
                                        </span>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </template>
                        <div v-else class="d-flex align-center my-1">
                            <v-icon icon="mdi-av-timer" class="mr-2" style="max-width: 1em" color="black" />
                            <span class="title font-weight-semibold dark-text"> {{ timeString(estimate) }} </span>
                        </div>
                    </div>
                    <div class="my-3">
                        <v-form ref="contactFormRef">
                            <label for="firstname" class="caption text-uppercase text-grey">{{ t('labels.name') }}</label>
                            <v-text-field
                                id="firstName"
                                v-model="firstName"
                                :rules="nameRules"
                                bg-color="#fafafa"
                                clearable
                                clear-icon="mdi-close"
                                name="firstName"
                                class="my-1 font-weight-semibold"
                                variant="solo"
                                flat
                            />
                            <label for="phoneNumber" class="caption text-uppercase text-grey">
                                {{ t('labels.phoneNumber') }}
                            </label>
                            <v-text-field
                                id="phoneNumber"
                                v-model="phoneNumber"
                                :rules="phoneRules"
                                bg-color="#fafafa"
                                clearable
                                clear-icon="mdi-close"
                                class="my-1 font-weight-semibold"
                                variant="solo"
                                flat
                            />
                            <v-row class="my-2">
                                <v-col cols="6" class="pr-1">
                                    <v-btn
                                        id="prevButton"
                                        :disabled="isLoading"
                                        variant="outlined"
                                        rounded
                                        block
                                        size="large"
                                        color="#36a14b"
                                        @click="$emit('nav:prev')"
                                    >
                                        <span class="subheading">{{ t('buttons.back') }}</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="6" class="pl-1">
                                    <v-btn
                                        id="nextButton"
                                        :loading="isLoading"
                                        :disabled="isLoading || !isSubmittable"
                                        rounded
                                        block
                                        size="large"
                                        flat
                                        color="primary"
                                        @click="next"
                                    >
                                        <span class="subheading">{{ t('buttons.book') }}</span>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </div>
                    <div v-if="estimate">
                        <v-divider class="mb-4" />
                        <div class="mb-3">
                            <p class="text-grey caption text-uppercase mb-1">
                                {{ t('labels.pickupLocation') }}
                            </p>
                            <location-text>
                                <template #icon>
                                    <location-type-icon :size="24" is-embark-location :is-bus-stop="estimate.pickupType === 2" />
                                </template>
                                {{ ShortAddressFilter(estimate.scheduledPickupAddress) }}
                            </location-text>
                        </div>
                        <div>
                            <p class="text-grey caption text-uppercase mb-1">
                                {{ t('labels.dropoffLocation') }}
                            </p>
                            <location-text>
                                <template #icon>
                                    <location-type-icon :size="24" :is-bus-stop="estimate.dropoffType === 2" />
                                </template>
                                {{ ShortAddressFilter(estimate.scheduledDropoffAddress) }}
                            </location-text>
                        </div>
                        <v-divider class="mt-4" />
                        <div class="d-flex align-center py-4 dark-text" style="font-size: 16px">
                            <div class="d-flex align-center">
                                <v-icon icon="mdi-account" color="grey-darken-1" class="mr-2" />
                                <span>{{ estimate.numRiders }} {{ t('labels.numRiders') }}</span>
                            </div>
                            <v-spacer />
                            <div class="d-flex align-center">
                                <v-icon icon="mdi-timelapse" color="grey-darken-1" class="mr-2" />
                                <span>{{ HumanizeFilter(estimate.travelDuration, 'seconds') }}</span>
                            </div>
                        </div>
                        <v-divider />
                    </div>
                    <div v-if="privacyPageUrl" class="mt-4 mb-1 text-center">
                        <a :href="sanitizeUrl(privacyPageUrl)" target="_blank" class="caption text-link">
                            {{ t('labels.privacyPageUrl') }}
                        </a>
                    </div>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import LocationTypeIcon from '@/components/LocationTypeIcon.vue';
import { RequestEstimate } from '@/Models/RequestEstimate';
import AppConfig from '@/AppConfig';
import moment from 'moment';
import 'moment-timezone';
import { RequestedPickupTsType } from '@/Models/RequestedPickupTsType';
import { RequestParameters } from '@/Models/RequestParameters';
import LocationText from '@/components/LocationText.vue';
import { useI18n } from 'vue-i18n';
import i18n from '@/Plugins/i18n';
import { ShortAddressFilter } from '@/Filters/AddressFilters';
import { HumanizeFilter } from '@/Filters/DateFilters';
import { SetMapViewArgs } from '@/Models/MapLocation';
import { isValidNumber } from 'libphonenumber-js';
import { VForm } from 'vuetify/components';
import { sanitizeUrl } from '@braintree/sanitize-url';

const contactFormRef = ref<VForm>();
const nameRules = [(value: string) => !!value || t('errorMessages.fieldRequired', { field: t('labels.name') })];
const phoneRules = [
    (value: string) => {
        if (!value) return t('errorMessages.fieldRequired', { field: t('labels.phoneNumber') });
        if (!isValidNumber(value, 'NO')) return t('errorMessages.isValidPhoneNumberErrorMessage');
        return true;
    }
];

const { t } = useI18n();

interface Props {
    modelValue: RequestParameters;
    estimate?: RequestEstimate;
    requestEstimates?: RequestEstimate[];
    isLoading?: boolean;
}
interface Emits {
    (e: 'update:modelValue', value: RequestParameters): void;
    (e: 'map:fitTrip', value: any): void;
    (e: 'map:setView', value: SetMapViewArgs): void;
    (e: 'nav:prev'): void;
    (e: 'nav:next'): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const firstName = ref('');
const phoneNumber = ref('');
const now = ref(moment().tz(AppConfig.timezone));
const showPickupPointTimeout = ref();
const timer = ref();

const heading = computed(() => {
    const { modelValue, estimate } = props;
    const { requestedPickupTsType, requestedPickupTs } = modelValue || ({} as RequestParameters);
    const { requestedPickupTs: estimatedRequestedPickupTs, requestedFlexibility } = estimate || ({} as RequestEstimate);

    if (props.requestEstimates) return t('messages.availablePickupTimes');
    else if (requestedPickupTsType === RequestedPickupTsType.Now) return t('messages.nextAvailableTime');
    else if (
        estimatedRequestedPickupTs &&
        requestedPickupTs &&
        (estimatedRequestedPickupTs.diff(requestedPickupTs, 's') > requestedFlexibility ||
            estimatedRequestedPickupTs.diff(requestedPickupTs, 's') < 0)
    ) {
        return t('messages.closestPickupTime');
    } else {
        return t('messages.weCanPickYouUp');
    }
});

const privacyPageUrl = computed(() => {
    const { service: { organization: { privacyPageUrl = null } = {} } = {} } = props.modelValue;
    return privacyPageUrl;
});

const isSubmittable = computed(() => !!firstName.value && !!phoneNumber.value);

function init() {
    if (props.modelValue) {
        const { firstName: fs, phoneNumber: pn } = props.modelValue;

        if (fs) firstName.value = fs;
        if (pn) phoneNumber.value = pn;
    }

    setTimeout(() => {
        emit('map:fitTrip', {
            paddingTopLeft: [50, Math.min((window.innerHeight * 0.8) / 2, 80)],
            paddingBottomRight: [50, Math.min((window.innerHeight * 0.8) / 2, 20)],
            noMoveStart: true
        });

        const { scheduledPickupLocation } = props.estimate || ({} as RequestEstimate);
        if (scheduledPickupLocation)
            showPickupPointTimeout.value = setTimeout(
                () => emit('map:setView', { center: scheduledPickupLocation, zoom: 16 }),
                AppConfig.showPickupPointDelay
            );
    }, 250);

    timer.value = setInterval(tick, 1000);
}

function destroy() {
    clearTimeout(showPickupPointTimeout.value);
    clearInterval(timer.value);
}

function timeString(requestEstimate: RequestEstimate) {
    const { requestedPickupTs, scheduledPickupTs } = requestEstimate || ({} as RequestEstimate);
    if (!requestedPickupTs || !scheduledPickupTs) return '';

    const diff = requestedPickupTs.diff(now.value, 'm');

    // if in 10 minutes
    if (diff < 10 && scheduledPickupTs.diff(now.value, 'm') < 10) {
        return scheduledPickupTs.fromNow();
    }

    return scheduledPickupTs.clone().calendarApprox();
}

function estimateComparator(value: RequestEstimate, radioValue: RequestEstimate) {
    return value && value.requestedPickupTs.isSame(radioValue.requestedPickupTs);
}

function onSelectedEstimateChange(value: RequestEstimate) {
    const { requestedPickupTs, id: estimateId } = value;
    emit('update:modelValue', {
        ...props.modelValue,
        requestedPickupTs,
        estimateId
    });
}

function tick() {
    now.value = moment().tz(AppConfig.timezone);

    // if scheduled time is same or before, go back
    // this prevents the user from creating requests in the past
    if (props.estimate.scheduledPickupTs.isSameOrBefore(now.value)) emit('nav:prev');
}

async function next() {
    const { valid } = (await contactFormRef.value?.validate()) || {};

    if (!valid) return;

    const fn = firstName.value;
    const pn = phoneNumber.value;

    emit('update:modelValue', {
        ...props.modelValue,
        firstName: fn,
        phoneNumber: pn
    });
    emit('nav:next');
}

defineExpose({ init, destroy });
</script>
