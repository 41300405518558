<template>
    <v-dialog :model-value="_modelValue" persistent max-width="320" content-class="errorDialog">
        <v-card>
            <v-card-text class="pa-4">
                <div class="title mb-3">
                    {{ t('errorMessages.heading') }}
                </div>
                <div v-html="sanitizeHtml(errorMessage)"></div>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn variant="text" color="primary" @click="closeError">
                    {{ t('buttons.ok') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts" setup>
import { useVModel } from '@vueuse/core';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import sanitizeHtml from 'sanitize-html';

interface ErrorMessage {
    message: string;
    status: number;
}

interface Props {
    modelValue: boolean;
    error?: ErrorMessage | null;
}

const props = defineProps<Props>();
const _modelValue = useVModel(props, 'modelValue');
const _error = useVModel(props, 'error');

const { t, te } = useI18n();

const errorMessage = computed(() => {
    if (!props.error) return;

    return props.error.message.match(/\w+(\.\w+)+/)
        ? te(props.error.message)
            ? t(props.error.message)
            : t('errorMessages.defaultErrorMessage')
        : `${t('errorMessages.defaultErrorMessage')} (${props.error.status})`;
});

function closeError() {
    _modelValue.value = false;
    _error.value = null;
}
</script>

<style></style>
