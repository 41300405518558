<template>
    <l-marker v-if="latLng" :lat-lng="latLng">
        <l-icon :icon-size="[80, 80]" :icon-anchor="[40, 40]" class-name="vehicle-position-marker">
            <div class="vehicle-position-marker__inner">
                <img :src="iconUrl" class="vehicle-position-marker__img" :style="{ transform: `rotate(${bearing}deg)` }" />
            </div>
        </l-icon>
    </l-marker>
</template>

<script lang="ts" setup>
import { VehicleLocationDto } from '@/Dto/VehicleLocationDto';
import { LMarker, LIcon } from '@vue-leaflet/vue-leaflet';
import { isEmpty } from 'lodash-es';
import { computed } from 'vue';

interface Props {
    modelValue: VehicleLocationDto | null;
    zIndexOffset: number;
    vehicleMarker?: string;
}

const props = defineProps<Props>();

const latLng = computed(() =>
    !isEmpty(props.modelValue?.location)
        ? { lat: props.modelValue.location.coordinates[1], lng: props.modelValue.location.coordinates[0] }
        : null
);
const bearing = computed(() => props.modelValue?.bearing || 0);

const iconUrl = computed(() => {
    switch (props.vehicleMarker) {
        case 'yellow':
            return '/icons/yellowVehicle.svg';

        case 'red':
            return '/icons/redVehicle.svg';

        default:
            return '/icons/defaultVehicle.svg';
    }
});
</script>

<style lang="scss">
.vehicle-position-marker {
    --vehiclePositionIconSize: 80px;

    background: none;
    border: 0 none;

    &__inner {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__img {
        position: absolute;
        height: var(--vehiclePositionIconSize);
        width: var(--vehiclePositionIconSize);
        left: calc(50% - (var(--vehiclePositionIconSize) / 2));
        top: calc(50% - (var(--vehiclePositionIconSize) / 2));
        transform-origin: center center;
    }
}
</style>
