export enum TimeRuleDaysDto {
    Mondays = 1,
    Tuesdays = 2,
    Wednesdays = 3,
    Thursdays = 4,
    Fridays = 5,
    Saturdays = 6,
    Sundays = 7,
    Weekdays = 8,
    Weekends = 9,
    Everyday = 10
}

export const AbstractDayTimeRuleToDays = {
    8: [1, 2, 3, 4, 5],
    9: [6, 7],
    10: [1, 2, 3, 4, 5, 6, 7]
};
