import { ILocalizationService } from '@/Services/ILocalizationService';
import { inject, injectable } from 'inversify';
import { AxiosStatic } from 'axios';
import i18n from '@/Plugins/i18n';
import moment from 'moment';

const loadedLanguages = [i18n.global.locale];

function setI18nLanguage(lang: string): void {
    i18n.global.locale = lang;
    document.querySelector('html').setAttribute('lang', lang);
    moment.locale(lang);
}

@injectable()
export default class LocalizationService implements ILocalizationService {
    @inject('axios')
    protected axios: AxiosStatic;

    loadLanguage(lang: string): void {
        if (i18n.global.locale === lang || loadedLanguages.indexOf(lang) > -1) {
            return setI18nLanguage(lang);
        } else {
            this.axios
                .get(`/jsl10n/HentMeg.Web.Resources.HentMegResources?camel=true&json=true&lang=${lang}`)
                .then(res => {
                    const langMsg = 'data.hentMeg.web.resources.hentMegResources'
                        .split('.')
                        .reduce((acc, curr) => (acc && acc[curr] ? acc[curr] : null), res);
                    i18n.global.setLocaleMessage(lang, langMsg);
                    loadedLanguages.push(lang);
                    setI18nLanguage(lang);
                })
                .catch(err => {
                    throw err;
                });
        }
    }
}
