<template>
    <div class="d-none"></div>
</template>

<script lang="ts" setup>
import 'leaflet-curve';
import L, { PathOptions, LatLngLiteral, Map } from 'leaflet';
import { onBeforeUnmount, onMounted, ref } from 'vue';

interface Props {
    map: InstanceType<typeof Map>;
    latLngs: LatLngLiteral[];
    lineOptions?: PathOptions;
}

const props = withDefaults(defineProps<Props>(), { latLngs: () => [] });

const curve = ref<InstanceType<typeof L.Curve>>();

onMounted(() => {
    let point1 = [props.latLngs[0].lat, props.latLngs[0].lng];
    let point2 = [props.latLngs[1].lat, props.latLngs[1].lng];

    if (point1[1] > point2[1]) {
        //in case of drawing from right to left switch drawing direction so curve isn't inverted
        let tmp = point1;
        point1 = point2;
        point2 = tmp;
    }

    let offsetX = point2[1] - point1[1];
    let offsetY = point2[0] - point1[0];

    let r = Math.sqrt(Math.pow(offsetX, 2) + Math.pow(offsetY, 2));
    let theta = Math.atan2(offsetY, offsetX);
    let thetaOffset = 3.14 / 10;
    let r2 = r / 2 / Math.cos(thetaOffset);
    let theta2 = theta + thetaOffset;

    let midpointX = r2 * Math.cos(theta2) + point1[1];
    let midpointY = r2 * Math.sin(theta2) + point1[0];

    let midpointLatLng = [midpointY, midpointX];

    curve.value = L.curve(['M', point1, 'Q', midpointLatLng, point2], props.lineOptions).addTo(props.map);
});

onBeforeUnmount(() => {
    curve.value.remove();
});
</script>
