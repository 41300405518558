import { inject, ref, computed, toValue } from 'vue';
import { IServiceService, IServiceServiceId } from '@/Services/IServiceService';
import { IRequestService, IRequestServiceId } from '@/Services/IRequestService';
import { RequestParameters } from '@/Models/RequestParameters';
import { RequestedPickupTsType } from '@/Models/RequestedPickupTsType';
import { RequestEstimate } from '@/Models/RequestEstimate';
import { EstimateServiceDto } from '@/Dto/EstimateServiceDto';
import { useI18n } from 'vue-i18n';
import i18n from '@/Plugins/i18n';
import { getMatchingTimeRule, timeRulesToClosestMomentInFuture } from '@/Helpers/OperatingHoursHelpers';
import { parse } from 'libphonenumber-js';
import { pushToDataLayer } from '@/Plugins/gtm';
import { useRouter } from 'vue-router';
import { useContainer } from '@/Plugins/inversify';
import { isAxiosError } from 'axios';
import { LoadingOverlayProvideKey } from '@/InjectionKeys';

export interface Emits {
    (e: 'update:params', value: RequestParameters): void;
    (e: 'error', value: any): void;
}

export function useBooking({ emit }: { emit: Emits }) {
    const router = useRouter();
    const { showOverlay, hideOverlay } = inject(LoadingOverlayProvideKey);

    const { t } = useI18n();

    const container = useContainer();
    const requestService = container.get<IRequestService>(IRequestServiceId);
    const serviceService = container.get<IServiceService>(IServiceServiceId);

    const isLoading = ref(false);
    const params = ref<RequestParameters>({
        numRiders: 1,
        requestedPickupTsType: RequestedPickupTsType.Now
    });

    const estimate = ref<RequestEstimate>(null);
    const requestEstimates = ref<RequestEstimate[]>(null);

    const trip = computed(() => {
        const { scheduledPickupLocation, scheduledDropoffLocation, pickupWalkingPolyline, dropoffWalkingPolyline } =
            estimate.value || ({} as RequestEstimate);
        const { requestedPickupLocation, requestedDropoffLocation } = params.value || ({} as RequestParameters);

        return {
            scheduledPickupLocation,
            scheduledDropoffLocation,
            pickupWalkingPolyline,
            dropoffWalkingPolyline,
            requestedPickupLocation,
            requestedDropoffLocation
        };
    });

    function updateParams(value: RequestParameters) {
        params.value = {
            ...params.value,
            ...value
        };

        emit('update:params', params.value);
    }

    async function getServiceEstimate() {
        try {
            // set loading state
            isLoading.value = true;
            showOverlay();

            pushToDataLayer({ event: 'booking.action', type: 'service_estimate' });

            // set the appropriate service for the trip
            const service = await serviceService.getServiceEstimate(params.value);
            updateParams({ service });

            pushToDataLayer({ event: 'booking.action', type: 'service_estimated' });

            return true;
        } catch (e) {
            pushToDataLayer({ event: 'app.error', error: e });

            emit('error', e);

            // reset dropoff
            updateParams({
                requestedDropoffAddress: undefined,
                requestedDropoffLocation: undefined,
                requestedDropoffPlaceId: undefined
            });

            return false;
        } finally {
            // set loading state
            isLoading.value = false;
            hideOverlay();
        }
    }

    async function getRequestEstimate() {
        try {
            isLoading.value = true;
            showOverlay(t('labels.estimatingRequest'));

            pushToDataLayer({ event: 'booking.action', type: 'request_estimate' });

            const { requestedPickupTs, requestedPickupTsType } = toValue(params.value);
            const paramsEstimate = { ...params.value, requestedPickupTs, requestedPickupTsType };

            const requestEstimateResponse =
                requestedPickupTsType === RequestedPickupTsType.Now
                    ? await requestService.getNextAvailableRequestEstimate(paramsEstimate)
                    : await requestService.getRequestEstimates(paramsEstimate);

            isLoading.value = false;
            hideOverlay();

            let requestEstimate: RequestEstimate;
            // if there are multiple results
            if (requestEstimateResponse instanceof Array) {
                // set as options for next step
                // TODO: add to model
                requestEstimates.value = requestEstimateResponse;

                // select last item in the list, that's closest to the requested time
                requestEstimate = requestEstimateResponse[requestEstimateResponse.length - 1];
            } else {
                requestEstimate = requestEstimateResponse;
            }

            // set last estimate as default estimate
            estimate.value = requestEstimate;

            // set requested time to estimated requested time
            updateParams({
                requestedPickupTs: requestEstimate.requestedPickupTs,
                estimateId: requestEstimate.id
            });

            pushToDataLayer({ event: 'booking.action', type: 'request_estimated' });

            return true;
        } catch (e) {
            pushToDataLayer({ event: 'app.error', error: e });

            isLoading.value = false;
            hideOverlay();

            if (isAxiosError(e) && e.status === 400 && e.message === 'errorMessages.badRequestError') {
                const { service, requestedPickupTs } = params.value || ({} as RequestParameters);
                const { timeRules } = service || ({} as EstimateServiceDto);

                if (timeRules) {
                    let errorMessage = '';
                    const matchingTimeRule = getMatchingTimeRule(requestedPickupTs, timeRules);

                    if (matchingTimeRule) {
                        const serviceEndTime = requestedPickupTs
                            .clone()
                            .locale(i18n.global.locale.value)
                            .startOf('day')
                            .add(matchingTimeRule.endTs, 's');

                        // cannot find trip until service ends at {matchingRule.endTs}
                        errorMessage += t('errorMessages.noSuitableTripFoundUntilClosingTime', {
                            serviceEndTime: serviceEndTime
                                .calendar(null, {
                                    nextWeek: `${t('days.onDay')} ${t('moment.calendar.nextWeek')}`
                                })
                                .toLowerCase()
                        });

                        // fetch the next service opening time using the serviceEndTime as starting point
                        const nextAvailableTime = timeRulesToClosestMomentInFuture(serviceEndTime, timeRules);
                        if (nextAvailableTime) {
                            errorMessage += ' ';
                            errorMessage += t('errorMessages.serviceResumes', {
                                serviceStartTime: nextAvailableTime
                                    .calendar(null, {
                                        nextWeek: `${t('days.onDay')} ${t('moment.calendar.nextWeek')}`
                                    })
                                    .toLowerCase()
                            });
                        }
                    } else {
                        // cannot find trip at the moment
                        errorMessage += t('errorMessages.noSuitableTripAtThisTime');
                    }

                    e.message = errorMessage;

                    emit('error', e);
                } else {
                    // failed to find estimate for given requestedPickupTs
                    emit('error', e);
                }
            } else {
                emit('error', e);
            }

            return false;
        }
    }

    async function createRequest() {
        try {
            isLoading.value = true;
            showOverlay();

            pushToDataLayer({ event: 'booking.action', type: 'request_create' });

            // make sure phone number has country code
            const { phoneNumber } = params.value;
            const parsedPhoneNumber = parse(phoneNumber, {
                defaultCountry: 'NO',
                extended: true
            });

            if (!phoneNumber.startsWith(`+${parsedPhoneNumber.countryCallingCode}`)) {
                updateParams({
                    phoneNumber: `+${parsedPhoneNumber.countryCallingCode}${parsedPhoneNumber.phone}`
                });
            }

            // make the request
            const createRequestResponse = await requestService.createRequest(params.value);

            pushToDataLayer({ event: 'booking.action', type: 'request_created' });

            // redirect to request details page with thank you message
            router.push({
                name: 'request-details',
                params: { ...createRequestResponse }
            });

            isLoading.value = false;
            hideOverlay();
        } catch (e) {
            pushToDataLayer({ event: 'app.error', error: e });

            isLoading.value = false;
            hideOverlay();

            emit('error', e);
        }
    }

    return {
        createRequest,
        getRequestEstimate,
        getServiceEstimate,
        updateParams,
        trip,
        isLoading,
        requestEstimates,
        estimate,
        params
    };
}
