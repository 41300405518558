<template>
    <v-btn icon color="#fff" class="round-btn" @click="locate">
        <v-icon icon="mdi-crosshairs-gps" size="32" :class="{ blink: isLocating }" />
    </v-btn>
</template>

<script lang="ts" setup>
import { nextTick, ref } from 'vue';

interface Props {
    options?: PositionOptions;
    geolocation: Geolocation;
}
interface Emits {
    (e: 'input', value: any): void;
    (e: 'locating', value: boolean): void;
    (e: 'error', value: any): void;
}

const props = withDefaults(defineProps<Props>(), {
    options: () => ({
        enableHighAccuracy: true,
        maximumAge: 2000,
        timeout: 5000
    }),
    geolocation: () => navigator.geolocation
});

const emit = defineEmits<Emits>();

const isLocating = ref(false);

async function locate() {
    try {
        if (isLocating.value) return;
        isLocating.value = true;
        emit('locating', true);

        const position = await new Promise((resolve, reject) => {
            props.geolocation.getCurrentPosition(
                (position: GeolocationPosition) => resolve(position),
                error => reject(error),
                props.options
            );
        });

        isLocating.value = false;
        emit('locating', false);
        await nextTick(() => emit('input', position));
    } catch (error) {
        isLocating.value = false;
        emit('locating', false);
        await nextTick(() => emit('error', error));
    }
}

defineExpose({ locate });
</script>

<style lang="scss" scoped>
.blink {
    animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
}
</style>
