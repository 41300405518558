import { Map } from 'leaflet';

// this code prevents all native scaling when user uses pinch to zoom on iOS
// ref: https://stackoverflow.com/questions/37808180/disable-viewport-zooming-ios-10-safari
export function stopSafariScaling(map: Map) {
    if (!/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) return;

    map.getContainer().addEventListener(
        'touchmove',
        (event: any) => {
            if (event.scale !== 1) event.preventDefault();
        },
        false
    );
}
