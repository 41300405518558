import moment, { Moment } from 'moment';

export function NumberToMoment(value: number, timezone?: string): Moment {
    if (!value) return null;

    if (timezone) {
        return moment.unix(value).tz(timezone);
    } else {
        return moment.unix(value);
    }
}
