import { createI18n, type I18n } from 'vue-i18n';
import { nextTick } from 'vue';
import type { RouteLocationNormalized, NavigationGuardNext, RouteLocationRaw } from 'vue-router';
import { isEmpty, merge } from 'lodash-es';
import { useContainer } from '@/Plugins/inversify';
import { type AxiosStatic } from 'axios';
import { en, no } from 'vuetify/locale';

const defaultMessages: any = {
    en: {
        $vuetify: en
    },
    no: {
        $vuetify: no
    }
};

function setupI18n(options = { locale: 'no' }) {
    const i18n = createI18n({
        fallbackLocale: 'en',
        legacy: false,
        globalInjection: true,
        ...options
    });
    setI18nLanguage(i18n, options.locale);
    return i18n;
}

const i18n = setupI18n();
export default i18n;

export function setI18nLanguage(i18n: I18n<{}, {}, {}, string, false>, locale: string) {
    i18n.global.locale.value = locale;
    document.querySelector('html')?.setAttribute('lang', locale);
}

export async function loadLocaleMessages(i18n: I18n<{}, {}, {}, string, false>, locale: string) {
    const container = useContainer();
    const axios = container.get<AxiosStatic>('axios');

    try {
        // load locale messages with dynamic import
        const res = await axios.get(`/jsl10n/HentMeg.Web.Resources.HentMegResources?camel=true&json=true&lang=${locale}`);

        // set locale and locale message
        i18n.global.setLocaleMessage(locale, merge({}, defaultMessages[locale], res.data.hentMeg.web.resources.hentMegResources));
    } catch (e) {
        console.error(e);
    }

    return nextTick();
}

export async function routeMiddleware(to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) {
    const paramLocale = (Array.isArray(to.params.locale) ? to.params.locale[0] : to.params.locale) || 'no';

    // load locale messages
    if (!i18n.global.availableLocales.includes(paramLocale) || isEmpty(i18n.global.getLocaleMessage(paramLocale))) {
        await loadLocaleMessages(i18n, paramLocale);
    }

    // set i18n language
    setI18nLanguage(i18n, paramLocale);

    return next();
}

export function i18nRoute(to: any): RouteLocationRaw {
    return merge(to, { params: { locale: i18n.global.locale.value } });
}
