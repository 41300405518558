<template>
    <l-feature-group class="trip-markers" @add="onAdd">
        <l-marker
            v-if="pickupLatLng"
            :key="pickupIcon.options.className"
            class="pickup"
            :lat-lng="pickupLatLng"
            :icon="pickupIcon"
            :z-index-offset="100"
        />
        <l-marker v-if="dropoffLatLng" :key="dropoffIcon.options.className" class="dropoff" :lat-lng="dropoffLatLng" :icon="dropoffIcon" />
        <l-circle-marker
            v-if="pickupWalkingPolyline"
            class="walking-pickup"
            :lat-lng="pickupWalkingPolyline[0]"
            v-bind="circleMarkerOptions"
        />
        <l-polyline
            v-if="pickupWalkingPolyline"
            class="walking-line-pickup"
            :lat-lngs="pickupWalkingPolyline"
            v-bind="walkingPolylineOptions"
        />
        <l-circle-marker
            v-if="dropoffWalkingPolyline"
            class="walking-dropoff"
            :lat-lng="dropoffWalkingPolyline[dropoffWalkingPolyline.length - 1]"
            v-bind="circleMarkerOptions"
        />
        <l-polyline
            v-if="dropoffWalkingPolyline"
            class="walikng-line-dropoff"
            :lat-lngs="dropoffWalkingPolyline"
            v-bind="walkingPolylineOptions"
        />
        <leaflet-curve v-if="curveLatLngs" :map="mapRef" :lat-lngs="curveLatLngs" :line-options="tripPolylineOptions" class="curve" />
    </l-feature-group>
</template>

<script lang="ts" setup>
import { LFeatureGroup, LMarker, LPolyline, LCircleMarker } from '@vue-leaflet/vue-leaflet';
import L, { Icon, Map, DivIcon, PointExpression, LatLngBounds, FitBoundsOptions, LeafletEvent, LatLngLiteral } from 'leaflet';
import pickupIconUrl from '/icons/hentmeg-requestedPickup-icon.svg';
import dropoffIconUrl from '/icons/hentmeg-requestedDropoff-icon.svg';
import stopIconUrl from '/icons/hentmeg-stop-icon.svg';
import LeafletCurve from '@/components/LeafletCurve.vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype['_getIconUrl'];

Icon.Default.mergeOptions({ iconRetinaUrl, iconUrl, shadowUrl });

interface Trip {
    requestedPickupLocation?: LatLngLiteral;
    requestedDropoffLocation?: LatLngLiteral;
    scheduledPickupLocation?: LatLngLiteral;
    scheduledDropoffLocation?: LatLngLiteral;
    pickupWalkingPolyline?: LatLngLiteral[];
    dropoffWalkingPolyline?: LatLngLiteral[];
}

interface Props {
    modelValue: Trip;
}

const props = defineProps<Props>();
const { t } = useI18n();
const { name: breakpointName } = useDisplay();

const mapRef = ref<Map>();
const iconSizes = {
    xs: L.point(90, 90),
    sm: L.point(90, 90),
    md: L.point(120, 120),
    lg: L.point(140, 140),
    xl: L.point(140, 140)
};
const scheduledIconAnchor: PointExpression = [140 * 0.35, 140 * 0.471];

const tripPolylineOptions = {
    lineCap: 'butt',
    color: '#008744',
    weight: 5,
    dashArray: '10, 10'
};

const walkingPolylineOptions = {
    color: '#669df6',
    weight: 10,
    dashArray: '1, 18'
};

const circleMarkerOptions = {
    stroke: true,
    color: '#000',
    weight: 2,
    fill: true,
    fillColor: '#fff',
    fillOpacity: 1,
    radius: 6
};

const pickupLatLng = computed(() => props.modelValue.scheduledPickupLocation || props.modelValue.requestedPickupLocation);
const dropoffLatLng = computed(() => props.modelValue.scheduledDropoffLocation || props.modelValue.requestedDropoffLocation);
const curveLatLngs = computed<LatLngLiteral[] | null>(() =>
    props.modelValue?.scheduledPickupLocation && props.modelValue?.scheduledDropoffLocation
        ? [props.modelValue.scheduledPickupLocation, props.modelValue.scheduledDropoffLocation]
        : null
);

const pickupWalkingPolyline = computed(() => {
    const { pickupWalkingPolyline } = props.modelValue || {};
    if (
        !pickupWalkingPolyline ||
        (pickupWalkingPolyline.length <= 2 &&
            pickupWalkingPolyline[0].lat === pickupWalkingPolyline[1].lat &&
            pickupWalkingPolyline[0].lng === pickupWalkingPolyline[1].lng)
    ) {
        return null;
    }

    return pickupWalkingPolyline;
});

const dropoffWalkingPolyline = computed(() => {
    const { dropoffWalkingPolyline } = props.modelValue || {};
    if (
        !dropoffWalkingPolyline ||
        (dropoffWalkingPolyline.length <= 2 &&
            dropoffWalkingPolyline[0].lat === dropoffWalkingPolyline[1].lat &&
            dropoffWalkingPolyline[0].lng === dropoffWalkingPolyline[1].lng)
    ) {
        return null;
    }

    return dropoffWalkingPolyline;
});

const requestedIconAnchor = computed((): PointExpression => {
    const p = iconSizes[breakpointName.value];
    return [p.x * 0.385, p.y * 0.535];
});

const pickupIcon = computed(() => {
    const { scheduledPickupLocation = null } = props.modelValue;

    return new DivIcon({
        html: `<div class="trip-marker">
                        <img src="${scheduledPickupLocation ? stopIconUrl : pickupIconUrl}" />
                        ${scheduledPickupLocation ? `<div class="trip-marker__label">${t('labels.pickupPoint')}</div>` : ''}
                    </div>`,
        iconSize: iconSizes[scheduledPickupLocation ? 'xl' : breakpointName.value],
        iconAnchor: scheduledPickupLocation ? scheduledIconAnchor : requestedIconAnchor.value,
        className: scheduledPickupLocation ? 'scheduled-pickup' : 'requested-pickup'
    }) as Icon;
});

const dropoffIcon = computed(() => {
    const { scheduledDropoffLocation = null } = props.modelValue;

    return new DivIcon({
        html: `<div class="trip-marker">
                        <img src="${scheduledDropoffLocation ? stopIconUrl : dropoffIconUrl}" />
                        ${scheduledDropoffLocation ? `<div class="trip-marker__label">${t('labels.dropoffPoint')}</div>` : ''}
                    </div>`,
        iconSize: iconSizes[scheduledDropoffLocation ? 'xl' : breakpointName.value],
        iconAnchor: scheduledDropoffLocation ? scheduledIconAnchor : requestedIconAnchor.value,
        className: scheduledDropoffLocation ? 'scheduled-dropoff' : 'requested-dropoff'
    }) as Icon;
});

function onAdd(e: LeafletEvent) {
    mapRef.value = e.target._map;
}

function getBounds() {
    if (!props.modelValue) return null;

    const bounds = new LatLngBounds([]);

    if (pickupLatLng.value) {
        bounds.extend(pickupLatLng.value);
    }

    if (dropoffLatLng.value) {
        bounds.extend(dropoffLatLng.value);
    }

    if (pickupWalkingPolyline.value) {
        bounds.extend(pickupWalkingPolyline.value[0]);
    }

    if (dropoffWalkingPolyline.value) {
        bounds.extend(dropoffWalkingPolyline.value[dropoffWalkingPolyline.value.length - 1]);
    }

    return bounds;
}

function fitBounds(options?: FitBoundsOptions) {
    const bounds = getBounds();

    if (bounds.isValid()) mapRef.value.fitBounds(bounds, options);
}

defineExpose({ fitBounds, getBounds });
</script>

<style>
.trip-marker {
    position: relative;
}

.trip-marker__label {
    position: absolute;
    top: 0;
    color: #fff;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    width: 140px;
    left: 0;
    margin-left: -20px;
}
</style>
