import { IGeolocationService } from '@/Services/IGeolocationService';
import { injectable } from 'inversify';

@injectable()
export default class GeolocationService implements IGeolocationService {
    private currentPosition: GeolocationPosition;

    isAvailable = false;

    constructor() {
        if ('geolocation' in navigator) {
            this.getCurrentPosition(position => {
                this.isAvailable = true;
                this.currentPosition = position;
            });
        }
    }

    clearWatch(watchId: number): void {
        this.currentPosition = null;
        navigator.geolocation.clearWatch(watchId);
    }

    getCurrentPosition(successCallback: PositionCallback, errorCallback?: PositionErrorCallback, options?: PositionOptions): void {
        if (this.currentPosition) {
            // if we have current position from watchPosition, return that
            successCallback(this.currentPosition);
            return;
        }

        // otherwise query geolocation
        return navigator.geolocation.getCurrentPosition(
            position => {
                // we need this for FF which does not seem to detect the variable change
                this.isAvailable = true;
                successCallback(position);
            },
            errorCallback,
            options
        );
    }

    watchPosition(successCallback: PositionCallback, errorCallback?: PositionErrorCallback, options?: PositionOptions): number {
        return navigator.geolocation.watchPosition(
            position => {
                // same reason as getCurrentPosition
                this.isAvailable = true;
                this.currentPosition = position;
                successCallback(position);
            },
            err => {
                this.currentPosition = null;
                errorCallback(err);
            },
            options
        );
    }
}
