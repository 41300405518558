<template>
    <div class="datetime-picker">
        <v-row>
            <v-col cols="7" class="pr-2">
                <v-dialog ref="dialogDate" v-model="modalDate" persistent width="320" content-class="datePickerDialog">
                    <template #activator="{ props: tmplProps }">
                        <v-text-field
                            v-bind="tmplProps"
                            :model-value="dateFormated"
                            readonly
                            variant="solo"
                            rounded="0"
                            flat
                            hide-details
                            bg-color="#fafafa"
                        />
                    </template>
                    <v-date-picker
                        v-model="date"
                        :allowed-dates="allowedDates"
                        :show-current="true"
                        :locale="locale"
                        color="primary"
                        width="320"
                    >
                        <template #actions>
                            <v-spacer />
                            <v-btn variant="text" color="primary" @click="modalDate = false">{{ t('buttons.cancel') }}</v-btn>
                            <v-btn variant="text" color="primary" @click="updateDate(date)">{{ t('buttons.ok') }}</v-btn>
                        </template>
                    </v-date-picker>
                </v-dialog>
            </v-col>
            <v-col cols="5" class="pl-2">
                <v-dialog ref="dialogTime" v-model="modalTime" persistent width="290" content-class="timePickerDialog">
                    <template #activator="{ props: tmplProps }">
                        <v-text-field
                            v-bind="tmplProps"
                            :model-value="timeFormatted"
                            readonly
                            variant="solo"
                            rounded="0"
                            flat
                            hide-details
                            bg-color="#fafafa"
                        />
                    </template>
                    <time-picker v-model="time" :allowed-time="allowedTime" :minute-step="5" :min="minTime" :max="maxTime">
                        <template #actions>
                            <v-spacer />
                            <v-btn variant="text" color="primary" @click="modalTime = false">{{ t('buttons.cancel') }}</v-btn>
                            <v-btn variant="text" color="primary" @click="updateTime(time)">{{ t('buttons.ok') }}</v-btn>
                        </template>
                    </time-picker>
                </v-dialog>
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts" setup>
import moment, { Moment } from 'moment';
import 'moment-timezone';
import { computed, onMounted, ref, watch } from 'vue';
import AppConfig from '@/AppConfig';
import TimePicker from './TimePicker.vue';
import { ServiceTimeRulesDto } from '@/Dto/ServiceTimeRulesDto';
import { isWithinOperatingHours } from '@/Validators/OperatingHoursValidators';
import { useI18n } from 'vue-i18n';
import { useVModel } from '@vueuse/core';
import { nextTick } from 'vue';

const { t } = useI18n();

interface Props {
    modelValue: Moment;
    min?: Moment;
    max?: Moment;
    locale?: string;
    timeRules: ServiceTimeRulesDto[];
}

const props = withDefaults(defineProps<Props>(), { timeRules: () => [] });
const _modelValue = useVModel(props, 'modelValue');

const timeRegex = /(\d{0,2}):(\d{0,2})/;
const modalDate = ref(false);
const modalTime = ref(false);
const time = ref<string | null>();
const date = ref<Date>();

const dateFormated = computed(() =>
    props.modelValue.calendar(null, {
        sameDay: t('units.sameDay') as string,
        nextDay: t('units.nextDay') as string,
        nextWeek: AppConfig.dateFormat,
        lastDay: AppConfig.dateFormat,
        lastWeek: AppConfig.dateFormat,
        sameElse: AppConfig.dateFormat
    })
);

const timeFormatted = computed(() => getTimeString(props.modelValue));

const minTime = computed(() => {
    if (!props.timeRules || props.timeRules.length === 0) return undefined;

    const seconds = props.timeRules.reduce((acc, curr) => (acc = Math.min(curr.startTs, acc)), 86400);
    return secondsToTime(seconds);
});

const maxTime = computed(() => {
    if (!props.timeRules || props.timeRules.length === 0) return undefined;

    const seconds = props.timeRules.reduce((acc, curr) => (acc = Math.max(curr.endTs, acc)), 0);
    return secondsToTime(seconds);
});

watch(
    () => props.min,
    (value: Moment) => {
        const [, hour, minute] = time.value.match(timeRegex);

        //Update timer value
        var tmpDateTime = moment.tz(date.value, AppConfig.timezone).set({ h: parseInt(hour), m: parseInt(minute) });

        if (tmpDateTime.isBefore(value, 'm')) {
            time.value = getTimeString(value);
        }

        if (tmpDateTime.isBefore(value, 'd')) {
            date.value = toDate(value);
        }
    }
);

onMounted(() => {
    time.value = getTimeString(props.modelValue);
    date.value = toDate(props.modelValue);
});

function updateTime(value: string) {
    modalTime.value = false;

    //Get current time and update hour & minute
    const [, hour, minute] = value.match(timeRegex);

    //Raise input event
    _modelValue.value = props.modelValue.clone().set({
        h: parseInt(hour),
        m: parseInt(minute),
        s: 0,
        ms: 0
    });
}

function updateDate(value: Date) {
    modalDate.value = false;

    nextTick(() => {
        _modelValue.value = _modelValue.value.clone().set({
            date: value.getDate(),
            month: value.getMonth(),
            year: value.getFullYear()
        });
    });
}

function allowedDates(val: unknown) {
    var date = moment(val);

    return (
        (!props.min || date.isSameOrAfter(props.min, 'day')) &&
        (!props.max || date.isBefore(props.max, 'day')) &&
        (!props.timeRules || props.timeRules.length === 0 || isWithinOperatingHours(date, props.timeRules))
    );
}

function allowedTime(val: string) {
    const [, hour, minute] = val.match(timeRegex);
    const date = moment(props.modelValue)
        .tz(AppConfig.timezone)
        .set({ h: parseInt(hour), m: parseInt(minute) });

    return (
        (!props.min || date.isSameOrAfter(props.min, 'm')) &&
        (!props.max || date.isBefore(props.max, 'm')) &&
        (!props.timeRules || props.timeRules.length === 0 || isWithinOperatingHours(date, props.timeRules, 'm'))
    );
}

function getTimeString(dateTime: Moment) {
    return dateTime.format('HH:mm');
}

function toDate(dateTime: Moment): Date {
    return dateTime.toDate();
}

function secondsToTime(value: number) {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value - hours * 3600) / 60);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}
</script>

<style lang="scss">
.datetime-picker {
    :deep(.v-date-picker-table) {
        th {
            font-size: 14px;
        }

        .v-btn {
            font-size: 14px;
        }
    }

    :deep(.v-text-field input) {
        font-size: 20px;
    }
}
</style>
