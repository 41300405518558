import moment from 'moment';
import { TimeRuleDaysDto } from '@/EnumsDto/TimeRuleDaysDto';
import i18n from '@/Plugins/i18n';

const MomentFilter = (value: number) => moment.unix(value);
const DateFilter = (value: moment.Moment) => value && value.format('DD.MM.YYYY');
const TimeFilter = (value: moment.Moment) => value && value.format('HH:mm');
const HumanizeFilter = (value: number, unit: moment.unitOfTime.DurationConstructor) => value && moment.duration(value, unit).humanize();
const DayFilter = (value: TimeRuleDaysDto) => i18n.global.t(`days.${TimeRuleDaysDto[value].toLowerCase()}`);

export { MomentFilter, DateFilter, TimeFilter, HumanizeFilter, DayFilter };
