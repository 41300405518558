import moment, { MomentInput, CalendarSpec } from 'moment';
import 'moment-timezone';
import { watch } from 'vue';
import { Composer } from 'vue-i18n';
import 'moment/dist/locale/nb';

export function setupMomentLocale(i18n: Composer) {
    watch(
        i18n.locale,
        value => {
            const locale = value === 'no' ? 'nb' : value;

            moment.locale(locale);
            moment.updateLocale(locale, i18n.messages.value[value]?.moment);
        },
        { immediate: true }
    );
}

moment.fn.calendarApprox = function (time?: MomentInput, formats?: CalendarSpec) {
    return this.calendar(time, {
        ...((this.localeData() as any)._config.calendarApprox || {}),
        ...formats
    });
};
